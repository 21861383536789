import React from "react";

export default function PropIcons({ studio }) {
  const props = studio.props;
  return (
    <>
      <ul>
        {props.map((prop) => {
          return <p>{prop}</p>;
        })}
      </ul>
    </>
  );
}
