import React from "react";

export default function Cake({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <polyline points="57.28,41.55 57.28,53.28 6.8,53.28 6.8,41.41 	" />
          <path
            d="M50.06,31.63L50.06,31.63c-1.95,0-3.53,1.58-3.53,3.53v0.74c0,1.85-1.5,3.34-3.34,3.34l0,0
     c-1.85,0-3.34-1.5-3.34-3.34v-2.76c0-1.62-1.31-2.94-2.94-2.94l0,0c-1.62,0-2.94,1.31-2.94,2.94v6.82c0,1.7-1.38,3.07-3.07,3.07
     l0,0c-1.7,0-3.07-1.38-3.07-3.07v-8.45c0-1.37-1.11-2.47-2.47-2.47l0,0c-1.37,0-2.47,1.11-2.47,2.47v2.36
     c0,1.67-1.35,3.02-3.02,3.02l0,0c-1.67,0-3.02-1.35-3.02-3.02v-0.9c0-1.42-1.15-2.57-2.57-2.57l0,0c-1.42,0-2.57,1.15-2.57,2.57
     v4.93c0,2.04-1.66,3.7-3.7,3.7l0,0c-2.04,0-3.7-1.66-3.7-3.7V27.7c0-3.18,2.58-5.76,5.76-5.76h43.99c3.18,0,5.76,2.58,5.76,5.76
     v10.8c0,1.71-1.39,3.1-3.1,3.1l0,0c-1.71,0-3.1-1.39-3.1-3.1v-3.34C53.59,33.21,52.01,31.63,50.06,31.63z"
          />
          <path d="M54.64,59.32H9.36c-3.19,0-5.77-2.58-5.77-5.77v-0.27h56.83v0.27C60.42,56.73,57.83,59.32,54.64,59.32z" />
          <path d="M20.21,21.94h-5.32v-6.9c0-0.64,0.52-1.15,1.15-1.15h3.02c0.64,0,1.15,0.52,1.15,1.15V21.94z" />
          <path
            d="M18.83,10.75c-0.73,0.55-1.76,0.62-2.54,0.16c-0.6-0.35-1.14-1-0.9-2.2c0.47-2.41,2.16-4.03,2.16-4.03
     S21.23,8.96,18.83,10.75z"
          />
          <path d="M34.66,21.94h-5.32v-6.9c0-0.64,0.52-1.15,1.15-1.15h3.02c0.64,0,1.15,0.52,1.15,1.15V21.94z" />
          <path
            d="M33.29,10.75c-0.73,0.55-1.76,0.62-2.54,0.16c-0.6-0.35-1.14-1-0.9-2.2c0.47-2.41,2.16-4.03,2.16-4.03
     S35.68,8.96,33.29,10.75z"
          />
          <path d="M48.47,21.94h-5.32v-6.9c0-0.64,0.52-1.15,1.15-1.15h3.02c0.64,0,1.15,0.52,1.15,1.15V21.94z" />
          <path
            d="M47.1,10.75c-0.73,0.55-1.76,0.62-2.54,0.16c-0.6-0.35-1.14-1-0.9-2.2c0.47-2.41,2.16-4.03,2.16-4.03
     S49.49,8.96,47.1,10.75z"
          />
        </g>
      </svg>
    </>
  );
}
