import React from "react";

export default function FamilyPhoto({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
 .st1{fill:none;stroke:#231F20;stroke-width:1.8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <path
            d="M31.98,15.69c0,0,12.09-18.8,25.94-1.47c13.95,17.45-25.94,42.91-25.94,42.91S-7.77,31.79,6.04,14.22
     C18.5-1.65,31.98,15.69,31.98,15.69z"
          />
          <g>
            <circle cx="15.06" cy="22.47" r="5.88" />
            <path d="M6.77,33.39c1.56-3,4.69-5.03,8.29-5.03c2.57,0,4.92,1.04,6.6,2.73c1.68,1.69,2.73,4.03,2.73,6.61v5.13v0.01" />
            <line x1="19.8" y1="47.9" x2="19.8" y2="38.07" />
          </g>
          <g>
            <circle cx="32" cy="32.68" r="5.34" />
            <path
              d="M23.52,50.97v-4.39c0-1.35,0.32-2.61,0.87-3.74v-0.01c1.39-2.79,4.27-4.72,7.61-4.72c2.33,0,4.46,0.94,6,2.48
         c0.66,0.66,1.2,1.41,1.61,2.25c0.56,1.12,0.87,2.39,0.87,3.73v4.36"
            />
            <line x1="27.56" y1="54.05" x2="27.56" y2="46.94" />
            <line x1="36.31" y1="54.12" x2="36.31" y2="46.94" />
          </g>
          <path
            d="M54.83,22.47c0,3.26-2.64,5.89-5.89,5.89c-3.07,0-5.59-2.35-5.85-5.36c3.29-0.99,5.18-2.79,6.12-4.01
     c0.56-0.73,1.58-0.91,2.37-0.45C53,19.34,54.83,20.75,54.83,22.47z"
          />
          <path d="M39.6,42.85v-7.33c0-3.96,3.21-7.17,7.16-7.17h4.35c2.93,0,5.44,1.76,6.54,4.28" />
          <line x1="43.24" y1="35.1" x2="42.21" y2="49.54" />
          <path d="M43.09,29.37v-8.95c0-3.24,2.63-5.87,5.87-5.87h0c3.24,0,5.87,2.63,5.87,5.87v8.97" />
        </g>
      </svg>
    </>
  );
}
