import React from "react";

export default function Rings({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none; stroke:#231F20; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;}`}
        </style>
        <g>
          <path
            d="M19.81,19.75c8.17-4.37,6.37-10.16,3.41-11.03c-2.58-0.76-3.41,2.03-3.41,2.03s-0.83-2.79-3.41-2.03
     C13.44,9.59,11.63,15.38,19.81,19.75z"
          />
          <path d="M34.32,27.21c2.1,2.92,3.33,6.5,3.33,10.37s-1.23,7.46-3.33,10.38v0.01" />
          <path
            d="M32.01,50.59c-3.18,2.99-7.46,4.82-12.18,4.82c-9.85,0-17.84-7.98-17.84-17.84c0-9.84,7.98-17.83,17.84-17.83
     c4.72,0,9,1.83,12.18,4.82"
          />
          <path d="M32.01,30.05c1.36,2.18,2.14,4.76,2.14,7.53c0,2.77-0.78,5.35-2.14,7.53c-0.01,0.01,0,0.01,0,0.01" />
          <path
            d="M29.68,47.95c-2.56,2.45-6.03,3.95-9.85,3.95c-7.91,0-14.33-6.42-14.33-14.33s6.42-14.32,14.33-14.32
     c3.83,0,7.29,1.5,9.85,3.95"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.6316 42.2466)"
            cx="44.18"
            cy="37.58"
            rx="17.83"
            ry="17.83"
          />
          <circle cx="44.18" cy="37.58" r="14.32" />
          <path
            d="M44.18,19.75c8.17-4.37,6.37-10.16,3.41-11.03c-2.58-0.76-3.41,2.03-3.41,2.03s-0.83-2.79-3.41-2.03
     C37.81,9.59,36.01,15.38,44.18,19.75z"
          />
        </g>
      </svg>
    </>
  );
}
