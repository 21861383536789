import React from "react";

export default function PreShoot({ packageDocReady, packageShow, heading }) {
  return (
    <>
      {packageDocReady && (
        <div className="my-5">
          <p className={heading}>Pre-Shoot</p>
          <div className="mx-2 md:mx-0 grid md:grid-cols-2">
            <div>
              {packageShow.advanceNotice > 24 ? (
                <div>Advance Notice: {packageShow.advanceNotice / 24} days</div>
              ) : (
                <div>Advance Notice: {packageShow.advanceNotice} hours</div>
              )}
            </div>
            <div>
              {packageShow.cancellationPolicy ? (
                <div>
                  Cancellation Policy: {packageShow.cancellationPolicy / 24}{" "}
                  days{" "}
                </div>
              ) : (
                <div>
                  Cancellation Policy: {packageShow.cancellationPolicy} hours{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
