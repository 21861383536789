import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
// import studioList from "../components/studioListComponents/studioList";
import StudioList from "../components/studiosComponents/StudioList";

export default function Studios() {
  const [studios, setStudios] = useState([]);

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "studios");
      const q = query(
        colRef,
        // where("adminPublish", "==", "true"),
        where("userPublish", "==", "true")
        // orderBy("reviewsPublishedRating", "desc"),
        // orderBy("profileImageURL", "desc")
        // orderBy("images", "desc")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setStudios(docs);
      // console.log("docs: ", docs);
    })();
  }, []);

  useEffect(() => {
    console.log("Studios: ", studios);
  }, [studios]);

  return <StudioList studios={studios} />;
}
