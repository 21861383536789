import React from "react";

export default function Celebration({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <path d="M42.7,37.9c1.8,3.6,2.2,6.6,0.7,8.1c-2.6,2.5-10.2-1-16.8-7.9s-10-14.5-7.4-17.1c1.6-1.5,5-0.9,8.9,1.4" />
          <polyline points="42.1,46.7 3.7,60.2 18.4,22.3 	" />
          <path d="M38.6,10.9c-1-1-1.4-2.5-1.1-3.8C37.6,6.3,38,5.6,38.7,5C40.3,3.3,43,3.4,44.6,5s1.6,4.3-0.1,5.9L25.8,29.2" />
          <path
            d="M53,18.1c1,1,2.4,1.4,3.8,1.2c0.8-0.1,1.6-0.5,2.2-1.1c1.7-1.6,1.7-4.3,0.1-5.9c-1.6-1.7-4.3-1.7-5.9-0.1
		L30.7,34.3"
          />
          <path
            d="M47.9,32.6c1,1,2.4,1.4,3.8,1.2c0.8-0.1,1.6-0.5,2.2-1.1c1.7-1.6,1.7-4.3,0.1-5.9c-1.6-1.7-4.3-1.7-5.9-0.1
		L35.4,39.1"
          />
          <path d="M14.1,33.5c0,0,4.3,3.7,12.4,4.6" />
          <path d="M11.1,41.3c0,0,9.9,5.5,25.7,4.4" />
          <path d="M7.9,49.5c0,0,5.6,3.6,19.6,2.3" />
        </g>
      </svg>
    </>
  );
}
