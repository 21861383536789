import React from "react";

export default function SolarCell({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h">
          <g>
            <path d="M50,48c.367,0,.705-.201,.879-.524l13-24c.168-.31,.16-.685-.02-.988-.181-.303-.507-.488-.859-.488H26c-.367,0-.705,.201-.879,.524l-13,24c-.168,.31-.16,.685,.02,.988,.181,.303,.507,.488,.859,.488h20v8h-3c-1.654,0-3,1.346-3,3v4c0,.552,.448,1,1,1h22c.552,0,1-.448,1-1v-4c0-1.654-1.346-3-3-3h-3v-8h5ZM26.596,24H61.321l-11.917,22H14.679l11.917-22Zm22.404,35v3H29v-3c0-.551,.449-1,1-1h18c.551,0,1,.449,1,1Zm-6-3h-8v-8h8v8Z" />
            <path d="M48.872,43.49l9-16c.174-.31,.171-.688-.008-.995s-.508-.495-.863-.495H28c-.361,0-.694,.195-.872,.51l-9,16c-.174,.31-.171,.688,.008,.995s.508,.495,.863,.495h29c.361,0,.694-.195,.872-.51Zm3.043-9.49h-7.705l3.375-6h7.705l-3.375,6Zm-17.705,0l3.375-6h7.705l-3.375,6h-7.705Zm6.58,2l-3.375,6h-7.705l3.375-6h7.705Zm-12.205-8h6.705l-3.375,6h-6.705l3.375-6Zm-4.5,8h6.705l-3.375,6h-6.705l3.375-6Zm15.625,6l3.375-6h7.705l-3.375,6h-7.705Z" />
            <path d="M21,13c0-4.411-3.589-8-8-8S5,8.589,5,13s3.589,8,8,8,8-3.589,8-8Zm-14,0c0-3.309,2.691-6,6-6s6,2.691,6,6-2.691,6-6,6-6-2.691-6-6Z" />
            <path d="M13,4c.552,0,1-.448,1-1V1c0-.552-.448-1-1-1s-1,.448-1,1V3c0,.552,.448,1,1,1Z" />
            <path d="M5.222,6.636c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-1.414-1.414c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l1.414,1.414Z" />
            <path d="M4,13c0-.552-.448-1-1-1H1c-.552,0-1,.448-1,1s.448,1,1,1H3c.552,0,1-.448,1-1Z" />
            <path d="M5.222,19.364l-1.414,1.414c-.391,.391-.391,1.023,0,1.414,.195,.195,.451,.293,.707,.293s.512-.098,.707-.293l1.414-1.414c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0Z" />
            <path d="M12,23v2c0,.552,.448,1,1,1s1-.448,1-1v-2c0-.552-.448-1-1-1s-1,.448-1,1Z" />
            <path d="M20.778,19.364c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l1.414,1.414c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-1.414-1.414Z" />
            <path d="M22,13c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1Z" />
            <path d="M20.071,6.929c.256,0,.512-.098,.707-.293l1.414-1.414c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0l-1.414,1.414c-.391,.391-.391,1.023,0,1.414,.195,.195,.451,.293,.707,.293Z" />
            <path d="M32,13h22c.552,0,1-.448,1-1,0-3.309-2.691-6-6-6-.69,0-1.365,.122-2.017,.363-1.257-2.071-3.508-3.363-5.983-3.363-3.164,0-5.875,2.087-6.723,5.065-1.862,.341-3.277,1.976-3.277,3.935,0,.552,.448,1,1,1Zm2.906-3.005c.036,.005,.114,.013,.15,.014,.513,.026,.937-.341,1.019-.835,.403-2.418,2.474-4.173,4.925-4.173,2.018,0,3.828,1.203,4.611,3.063,.111,.264,.33,.468,.601,.56,.271,.091,.568,.063,.817-.079,.63-.361,1.293-.544,1.971-.544,1.86,0,3.428,1.277,3.874,3h-19.608c.339-.598,.965-1.002,1.641-1.005Z" />
          </g>
        </g>
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
