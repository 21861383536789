import React from "react";

export default function Wifi({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j">
          <g>
            <path d="M36.998,55c-.553,0-1,.448-1,1,0,2.206-1.794,4-4,4s-4.001-1.794-4.001-4,1.794-4,4.001-4c.874,0,1.704,.276,2.4,.799,.44,.332,1.068,.243,1.4-.198,.332-.441,.243-1.068-.198-1.4-1.044-.786-2.289-1.201-3.601-1.201-3.309,0-6.001,2.691-6.001,6s2.692,6,6.001,6,6.001-2.691,6.001-6c0-.552-.447-1-1-1Z" />
            <path d="M63.002,16c-.43-.572-10.749-14-31.005-14S1.422,15.428,.992,16c-1.642,2.189-1.199,5.318,.985,6.975,2.184,1.659,5.322,1.232,6.997-.949,.313-.409,7.863-10.025,23.022-10.025s22.708,9.615,23.019,10.019c.953,1.259,2.408,1.981,3.989,1.981,1.089,0,2.125-.346,2.998-1,2.204-1.655,2.654-4.795,.999-7Zm-2.2,5.4c-.523,.393-1.146,.6-1.798,.6-.949,0-1.823-.433-2.399-1.194-.339-.441-8.483-10.806-24.609-10.806S7.725,20.367,7.387,20.808c-1.006,1.312-2.89,1.569-4.201,.573-1.312-.994-1.578-2.87-.595-4.181,.404-.539,10.138-13.201,29.405-13.201s29.001,12.662,29.405,13.2c.992,1.323,.723,3.208-.6,4.2Z" />
            <path d="M31.998,19c-14.108,0-22.419,9.312-22.767,9.708-.879,1.005-1.315,2.292-1.226,3.625,.089,1.333,.691,2.551,1.697,3.43,.919,.805,2.074,1.238,3.286,1.238,.113,0,.227-.004,.341-.011,1.332-.089,2.55-.692,3.427-1.694,.056-.063,5.71-6.295,15.241-6.295,9.473,0,15.029,6.06,15.256,6.312,.948,1.073,2.315,1.688,3.749,1.688,1.21,0,2.379-.439,3.29-1.237,1.006-.879,1.609-2.097,1.698-3.43,.089-1.333-.347-2.62-1.226-3.625-.348-.396-8.658-9.708-22.767-9.708Zm20.978,15.257c-1.195,1.045-3.17,.921-4.229-.277-.257-.285-6.394-6.98-16.749-6.98-10.525,0-16.684,6.905-16.744,6.975-.527,.603-1.258,.964-2.058,1.018-.802,.054-1.572-.208-2.176-.736-.604-.527-.965-1.258-1.018-2.058-.054-.799,.208-1.572,.735-2.174,.322-.369,8.049-9.025,21.261-9.025s20.938,8.656,21.26,9.024c.527,.603,.789,1.375,.735,2.175-.053,.8-.414,1.53-1.018,2.058Z" />
            <path d="M46.123,41.095c-.653-.521-6.599-5.095-14.127-5.095s-13.475,4.574-14.128,5.096c-2.139,1.711-2.492,4.853-.787,7.003,1.703,2.149,4.849,2.517,7.011,.826,.037-.029,3.822-2.925,7.904-2.925s7.867,2.896,7.896,2.918c.879,.697,1.982,1.082,3.103,1.082,1.529,0,2.954-.684,3.908-1.876,.835-1.042,1.214-2.348,1.067-3.676-.147-1.327-.803-2.518-1.847-3.353Zm-.781,5.779c-.998,1.246-2.94,1.482-4.214,.471-.176-.136-4.364-3.345-9.131-3.345s-8.959,3.21-9.135,3.348c-1.302,1.021-3.191,.797-4.213-.492-1.023-1.29-.812-3.174,.471-4.199,.596-.477,6.022-4.657,12.878-4.657s12.282,4.181,12.878,4.657c.626,.5,1.02,1.215,1.108,2.011,.089,.797-.139,1.58-.64,2.206Z" />
          </g>
        </g>
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
