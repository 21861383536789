import React from "react";

export default function ElectricFan({ className }) {
  return (
    <svg
      class={className.artist2}
      // fill="currentColor"
      // stroke="currentColor"
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      // style="enable-background:new 0 0 64 64;"
      // xml:space="preserve"
    >
      {/* <style type="text/css">
        {`.st0{fill:blue;stroke:#fb923c;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
      </style> */}
      <g>
        <circle  cx="32" cy="20.7" r="18.2" />
        <circle  cx="32.1" cy="20.7" r="5.2" />
        <path
          
          d="M27.8,17.7c-1.1-2.2-1.8-5.5,0.3-9.2c3.2-5.3,10.6-2.7,11,0.6c0.4,3.6-3.8,2.1-4.9,6.7"
        />
        <path
          
          d="M36.3,23.8c1,2.2,1.6,5.4-0.5,9c-3.2,5.3-10.6,2.7-11-0.6c-0.4-3.6,3.9-2,4.9-6.8v0"
        />
        <path
          
          d="M35.1,16.4c2.2-1,5.5-1.7,9,0.4c5.3,3.2,2.7,10.6-0.6,11c-3.6,0.4-2.1-3.8-6.7-4.8"
        />
        <path
          
          d="M27.4,18.4c-4.9-1-3.3-5.3-6.9-4.9c-3.3,0.3-5.9,7.8-0.6,11c3.6,2.2,6.9,1.4,9.2,0.4"
        />
        <polyline
          
          points="35.1,38.6 35.1,50.1 28.9,50.1 28.9,38.6 	"
        />
        <path
          
          d="M47.7,61.5H16.3v-6.8c0-2.5,2.1-4.6,4.6-4.6h22.2c2.5,0,4.6,2.1,4.6,4.6V61.5z"
        />
        <line  x1="26.5" y1="55.8" x2="28.2" y2="55.8" />
        <line  x1="36.4" y1="55.8" x2="38" y2="55.8" />
        <line  x1="31.5" y1="55.8" x2="33.1" y2="55.8" />
      </g>
    </svg>
  );
}
