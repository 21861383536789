import React from "react";

export default function Toddler({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <g>
            <g>
              <path d="M21.67,54.18l-2.6-10.11C18.9,43.44,18.33,43,17.68,43h-2.19c-0.65,0-1.22,0.44-1.39,1.07l-2.6,10.11" />
              <line x1="12.94" y1="48.59" x2="20.23" y2="48.59" />
            </g>
            <path
              d="M25.22,60.44H7.95c-1.77,0-3.21-1.44-3.21-3.21V39.95c0-1.77,1.44-3.21,3.21-3.21h17.27
         c1.77,0,3.21,1.44,3.21,3.21v17.27C28.43,59,27,60.44,25.22,60.44z"
            />
          </g>
          <g>
            <path
              d="M57.07,58.46l-17.2,1.62c-1.77,0.17-3.33-1.13-3.5-2.9l-1.62-17.2c-0.17-1.77,1.13-3.33,2.9-3.5l17.2-1.62
         c1.77-0.17,3.33,1.13,3.5,2.9l1.62,17.2C60.14,56.73,58.84,58.3,57.07,58.46z"
            />
            <path
              d="M48.06,53.02l-3.01,0.28c-0.69,0.07-1.31-0.44-1.38-1.14l-0.81-8.62c-0.07-0.69,0.44-1.31,1.14-1.38
         l3.01-0.28c2.17-0.2,4.09,1.39,4.29,3.56l0.31,3.28C51.82,50.9,50.23,52.82,48.06,53.02z"
            />
          </g>
          <g>
            <g>
              <path
                d="M18.88,18.55l-4.75-1.94l2.12-5.17L21,13.38c1.25,0.51,1.85,1.94,1.34,3.2l-0.26,0.64
             C21.57,18.46,20.14,19.06,18.88,18.55z"
              />
              <path
                d="M16.76,23.72l-4.75-1.94l2.12-5.17l4.75,1.94c1.25,0.51,1.85,1.94,1.34,3.2l-0.26,0.64
             C19.45,23.64,18.02,24.24,16.76,23.72z"
              />
            </g>
            <path
              d="M21.14,32.28L5.16,25.73c-1.64-0.67-2.43-2.55-1.75-4.19L9.96,5.56c0.67-1.64,2.55-2.43,4.19-1.75l15.98,6.55
         c1.64,0.67,2.43,2.55,1.75,4.19l-6.55,15.98C24.66,32.17,22.79,32.95,21.14,32.28z"
            />
          </g>
          <g>
            <path
              d="M50.78,23.38c-1.05,0.48-2.26,0.63-3.48,0.37c-3.02-0.65-4.94-3.62-4.29-6.64c0.65-3.02,3.62-4.94,6.64-4.29
         c1.08,0.23,2.03,0.76,2.76,1.49"
            />
            <path
              d="M53.33,31.44l-16.89-3.62c-1.73-0.37-2.84-2.08-2.47-3.81l3.62-16.89c0.37-1.73,2.08-2.84,3.81-2.47
         L58.3,8.27c1.73,0.37,2.84,2.08,2.47,3.81l-3.62,16.89C56.77,30.71,55.06,31.81,53.33,31.44z"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
