import React from "react";

export default function Air({ className }) {
  return (
    <svg
      className={className.artist1}
      id="_13-air"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <path d="M50,0c-5.771,0-9.193,2.086-11.048,3.835-2.339,2.207-3.681,5.302-3.681,8.492,0,5.514,3.752,9.672,8.729,9.672,7.91,0,10-5.233,10-8,0-1.654-1.346-3-3-3s-3,1.346-3,2.991c-.006,.206-.175,2.009-4,2.009-2.002,0-2.729-2.196-2.729-3.672,0-1.568,.639-3.034,1.798-4.127,1.526-1.439,3.922-2.2,6.931-2.2,5.383,0,8,2.617,8,8,0,6.355-3.645,10-10,10H17c-.552,0-1,.448-1,1v4c0,.552,.448,1,1,1h31c9.72,0,16-6.28,16-16,0-8.636-5.364-14-14-14Zm-2,28H18v-2h30c7.402,0,12-4.598,12-12,0-6.542-3.458-10-10-10-3.528,0-6.399,.949-8.303,2.745-1.564,1.476-2.426,3.458-2.426,5.583,0,2.819,1.624,5.672,4.729,5.672,5.932,0,6-3.837,6-4,0-.551,.449-1,1-1s1,.449,1,1c0,2.075-1.672,6-8,6-3.836,0-6.729-3.298-6.729-7.672,0-2.642,1.113-5.207,3.053-7.037,1.591-1.501,4.562-3.291,9.676-3.291,7.626,0,12,4.374,12,12,0,8.505-5.495,14-14,14Z" />
      <path d="M32,34H1c-.552,0-1,.447-1,1v4c0,.553,.448,1,1,1h31c6.355,0,10,3.645,10,10,0,5.383-2.617,8-8,8-3.008,0-5.404-.761-6.93-2.2-1.16-1.094-1.798-2.561-1.798-4.128,0-1.476,.727-3.672,2.729-3.672,3.825,0,3.994,1.804,4,2,0,1.654,1.346,3,3,3s3-1.346,3-3c0-2.767-2.09-8-10-8-4.976,0-8.729,4.158-8.729,9.672,0,3.189,1.341,6.285,3.681,8.493,1.855,1.749,5.278,3.835,11.048,3.835,8.636,0,14-5.364,14-14,0-9.72-6.28-16-16-16Zm2,28c-5.114,0-8.084-1.789-9.675-3.29-1.94-1.831-3.053-4.396-3.053-7.038,0-4.374,2.893-7.672,6.729-7.672,6.328,0,8,3.925,8,6,0,.552-.449,1-1,1s-1-.448-1-1c0-.163-.068-4-6-4-3.104,0-4.729,2.854-4.729,5.672,0,2.124,.861,4.106,2.426,5.583,1.904,1.796,4.775,2.745,8.303,2.745,6.542,0,10-3.458,10-10,0-7.402-4.598-12-12-12H2v-2h30c8.505,0,14,5.495,14,14,0,7.626-4.374,12-12,12Z" />
      <path d="M1,30H13c.552,0,1-.448,1-1v-4c0-.552-.448-1-1-1H1c-.552,0-1,.448-1,1v4c0,.552,.448,1,1,1Zm1-4H12v2H2v-2Z" />
      <path d="M6,21H24c.552,0,1-.448,1-1v-4c0-.552-.448-1-1-1H6c-.552,0-1,.448-1,1v4c0,.552,.448,1,1,1Zm1-4H23v2H7v-2Z" />
    </svg>
  );
}
