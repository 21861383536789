import React from "react";

export default function Price({ studio, studioDocReady }) {
  const action = () => {
    console.log("Studio Price");
  };

  return (
    <>
      {studioDocReady && (
        <div className="sticky left-0 bottom-0 w-full bg-white flex justify-center">
          <button
            onClick={action}
            className="w-full rounded-sm shadow-md md:w-1/4 my-1 text-white text-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 p-2 transition-all ease-in-out delay-75"
          >
            Starting from R{studio.baseRate}
          </button>
        </div>
      )}
    </>
  );
}
