import React, { useEffect, useState } from "react";
import { studioPropsArray } from "../../../assets/globalArrays/studioPropsArray";

export default function StudioProps({ addData, studio, docReady }) {
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("props");

  useEffect(() => {
    addData(dataId, data);
  }, [data, dataId, addData]);

  useEffect(() => {
    if (docReady && studio?.props) {
      setData(studio.props);
      setDataId(studioPropsArray()[0].id);
    }
  }, [docReady]);

  // useEffect(() => {
  //   console.log("")
  // })

  const onChange = (e) => {
    const { name, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(name);
  };

  return (
    <>
      <div className="ml-4">
        <h3 className="font-semibold text-gray-900">
          Please choose all the props that you provide
        </h3>
        <div className="flex flex-wrap gap-x-10 gap-y-5">
          {studioPropsArray().map((item) => (
            <div className="" key={item.value}>
              <label htmlFor={item.value}>
                <input
                  className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                  id={item.id}
                  type={item.type}
                  value={item.value}
                  name={item.name}
                  checked={data.includes(item.value)}
                  onChange={onChange}
                />
                <span className="ml-2">{item.title}</span>
                <img
                  className="h-56 rounded-lg"
                  src={item.image}
                  alt={item.value}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
