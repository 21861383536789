import React from "react";

export default function ClassicCar({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t">
          <g>
            <path d="M35,41h-8c-.552,0-1,.447-1,1s.448,1,1,1h8c.552,0,1-.447,1-1s-.448-1-1-1Z" />
            <path d="M35.595,29c1.326,0,2.405-1.233,2.405-2.75v-3.5c0-1.517-1.079-2.75-2.405-2.75h-7.651c-.861,0-1.66,.526-2.085,1.374l-1.756,3.5c-.466,.93-.418,2.096,.123,2.972,.453,.733,1.168,1.154,1.963,1.154h9.407Zm-9.667-2.204c-.151-.245-.221-.657-.037-1.025l1.755-3.5c.094-.186,.22-.271,.298-.271h7.651c.161,0,.405,.299,.405,.75v3.5c0,.451-.244,.75-.405,.75h-9.407c-.122,0-.223-.143-.261-.204Z" />
            <path d="M49.411,29c.815,0,1.558-.465,1.988-1.243,.515-.931,.486-2.106-.072-2.993-1.147-1.824-3.613-4.074-8.843-4.432-.588-.039-1.169,.173-1.625,.6-.545,.51-.858,1.267-.858,2.077v3.312c0,1.477,1.053,2.679,2.348,2.679h7.062Zm-7.411-2.679v-3.312c0-.3,.116-.516,.224-.616,.026-.025,.076-.065,.12-.065h.005c3.475,.237,5.994,1.448,7.285,3.501,.2,.317,.147,.722,.015,.962-.02,.035-.122,.21-.237,.21h-7.062c-.12,0-.348-.265-.348-.679Z" />
            <path d="M38,31c0-.553-.448-1-1-1s-1,.447-1,1v6c0,.553,.448,1,1,1s1-.447,1-1v-6Z" />
            <path d="M14,35c-3.86,0-7,3.141-7,7s3.14,7,7,7,7-3.141,7-7-3.14-7-7-7Zm0,12c-2.757,0-5-2.243-5-5s2.243-5,5-5,5,2.243,5,5-2.243,5-5,5Z" />
            <path d="M16,42c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2c.552,0,1-.447,1-1s-.448-1-1-1c-2.206,0-4,1.794-4,4s1.794,4,4,4,4-1.794,4-4c0-.553-.448-1-1-1s-1,.447-1,1Z" />
            <path d="M48,35c-3.86,0-7,3.141-7,7s3.14,7,7,7,7-3.141,7-7-3.14-7-7-7Zm0,12c-2.757,0-5-2.243-5-5s2.243-5,5-5,5,2.243,5,5-2.243,5-5,5Z" />
            <path d="M50,42c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2c.552,0,1-.447,1-1s-.448-1-1-1c-2.206,0-4,1.794-4,4s1.794,4,4,4,4-1.794,4-4c0-.553-.448-1-1-1s-1,.447-1,1Z" />
            <path d="M34,15c-5.595,0-7.803,.987-9.265,1.642-.394,.176-.705,.314-.994,.393-.427,.114-1.071,.287-4.81,7.383,0,0-.79,1.402-.98,1.581C5.115,25.55,.605,35.549,.006,40.889c-.017,.151,0,.306,.052,.449,.14,.39,.685,1.662,1.813,1.662h3.128c.552,0,1-.447,1-1,0-4.411,3.589-8,8-8s8,3.589,8,8c0,.553,.448,1,1,1s1-.447,1-1c0-5.514-4.486-10-10-10-9,0-9.95,9-9.95,9H2s2.642-13.485,15.964-13.001c1.242,.036,1.787-.964,2.572-2.355,0,0,3.351-6.075,3.928-6.737,.368-.117,.719-.274,1.088-.439,1.306-.584,3.28-1.467,8.448-1.467,25.271,0,28,16.085,28,23,0,.523-.031,.828-.061,1h-3.989c-.503-5.046-4.774-9-9.95-9-5.514,0-10,4.486-10,10,0,.553,.448,1,1,1s1-.447,1-1c0-4.411,3.589-8,8-8s8,3.589,8,8c0,.553,.448,1,1,1h5c1.666,0,2-1.147,2-3,0-23.197-22.962-25-30-25Z" />
          </g>
        </g>
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
