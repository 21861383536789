import React from "react";

export default function EventDate({ addData }) {
  const today = new Date().toISOString().split("T")[0];

  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };

  return (
    <>
      <div className="ml-4 relative w-1/2">
        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none"></div>
        <label>
          Event Date
          <input
            datepicker="true"
            datepicker-autohide="true"
            type="date"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full pl-5 sm:pl-10 py-1"
            placeholder="Select date"
            onChange={onChange}
            id="date"
            min={today}
            required
          />
        </label>
      </div>
    </>
  );
}
