import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

import Name from "../components/packageShowComponents/Name";
import Gallery from "../components/packageShowComponents/Gallery";
import PhotoCams from "../components/packageShowComponents/PhotoCams";
import PhotoEquipment from "../components/packageShowComponents/PhotoEquipment";
import VideoCams from "../components/packageShowComponents/VideoCams";
import VideoEquipment from "../components/packageShowComponents/VideoEquipment";
import Price from "../components/packageShowComponents/Price";
import Description from "../components/packageShowComponents/Description";
import PreShoot from "../components/packageShowComponents/PreShoot";
import DuringShoot from "../components/packageShowComponents/DuringShoot";
import PostShoot from "../components/packageShowComponents/PostShoot";
import AboutProfessional from "../components/packageShowComponents/AboutProfessional";

export default function PackageShow() {
  const hostname = window.location.hostname;
  const params = useParams();
  const { docId } = params;

  const [packageShow, setPackageShow] = useState();
  const [packageDocReady, setPackageDocReady] = useState(false);
  const [profile, setProfile] = useState();
  const [profileDocReady, setProfileDocReady] = useState(false);

  const iconClass = {
    artist1: "w-8 h-8 fill-current text-orange-400",
    artist2: "w-8 h-8 fill-none stroke-orange-400 stroke-2",
  };
  const containerClass = "m-2 flex items-center";
  const heading = "ml-2 md:ml-0 text-xl font-bold";

  useEffect(() => {
    const docRef = doc(db, "packages", docId);
    getDoc(docRef).then((snapshot) => {
      setPackageShow(snapshot.data());
      setPackageDocReady(true);
    });
  }, [docId]);

  useEffect(() => {
    if (packageShow) {
      console.log("useEffect, pull profiles");
      const docRef = doc(db, "profiles", packageShow.user);
      getDoc(docRef).then((snapshot) => {
        setProfile(snapshot.data());
        setProfileDocReady(true);
      });
      console.log("Package: ", packageShow);
    }
  }, [packageShow]);

  return (
    <>
      <div autoFocus className="">
        <Name packageShow={packageShow} />
        <div className=" md:w-4/5 mx-auto divide-y divide-gray-200">
          <Gallery
            packageShow={packageShow}
            packageDocReady={packageDocReady}
          />
          <Description
            packageShow={packageShow}
            packageDocReady={packageDocReady}
          />
          <PreShoot
            packageShow={packageShow}
            packageDocReady={packageDocReady}
            heading={heading}
          />
          <DuringShoot
            packageDocReady={packageDocReady}
            packageShow={packageShow}
            heading={heading}
          />
          <PostShoot
            packageDocReady={packageDocReady}
            packageShow={packageShow}
            heading={heading}
          />
          {packageDocReady && packageShow.services.includes("photography") && (
            <>
              <PhotoCams
                packageShow={packageShow}
                packageDocReady={packageDocReady}
                iconClass={iconClass}
                containerClass={containerClass}
                heading={heading}
              />
              <PhotoEquipment
                packageShow={packageShow}
                packageDocReady={packageDocReady}
                iconClass={iconClass}
                containerClass={containerClass}
                heading={heading}
              />{" "}
            </>
          )}

          {packageDocReady && packageShow.services.includes("videography") && (
            <>
              <VideoCams
                packageShow={packageShow}
                packageDocReady={packageDocReady}
                iconClass={iconClass}
                containerClass={containerClass}
                heading={heading}
              />
              <VideoEquipment
                packageShow={packageShow}
                packageDocReady={packageDocReady}
                iconClass={iconClass}
                containerClass={containerClass}
                heading={heading}
              />
            </>
          )}

          <AboutProfessional
            packageShow={packageShow}
            packageDocReady={packageDocReady}
            heading={heading}
            profile={profile}
            profileDocReady={profileDocReady}
          />
          <Price
            profile={profile}
            packageShow={packageShow}
            packageDocReady={packageDocReady}
          />
        </div>
      </div>
    </>
  );
}
