import React, { useState, useEffect } from "react";
import { videoEquipmentArray } from "../../assets/globalArrays/videoEquipmentArray";

export default function VideoEquipment({
  packageShow,
  packageDocReady,
  iconClass,
  containerClass,
  heading,
}) {
  const [videoEquipment, setVideoEquipment] = useState();
  const [displaySection, setDisplaySection] = useState(false);

  useEffect(() => {
    if (packageDocReady) {
      setVideoEquipment(packageShow.videoEquipment);
    }
  }, [packageDocReady, packageShow]);

  const display = () => {
    setDisplaySection(!displaySection);
  };

  return (
    <>
      <div className="cursor-pointer">
        <p className={heading} onClick={display}>
          Video Equipment
        </p>
        {displaySection && (
          <div className="grid grid-cols-2 md:grid-cols-4 md:gap-4">
            {videoEquipment &&
              videoEquipment.map((item) => {
                const i = videoEquipmentArray().findIndex(
                  (e) => e.value === item
                );
                // const i = 0;
                // console.log("Item: ", item);
                // console.log("i: ", i);
                const icon = videoEquipmentArray(iconClass)[i].icon;
                const title = videoEquipmentArray()[i].title;
                return (
                  <div key={item} className={containerClass}>
                    <span>{icon}</span>
                    <span className="capitalize ml-2 text-xs">{title}</span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
