import React, { useEffect } from "react";

export default function Name({ studio }) {
  //   useEffect(() => {
  //     console.log("Studio in Name: ", studio);
  //   });

  return (
    <div className="m-5">
      <p className="text-center text-2xl text-bold">{studio?.name}</p>
    </div>
  );
}
