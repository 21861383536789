import React, { useCallback, useState, useEffect } from "react";
import { GiShoppingCart } from "react-icons/gi";
// import ProBookingContactEmail from "./proBookingFormComponents/ProBookingContactEmail";
// import ProBookingContactNumber from "./proBookingFormComponents/ProBookingContactNumber";
// import ProBookingContactPerson from "./proBookingFormComponents/ProBookingContactPerson";
// import ProBookingPhotoDuration from "./proBookingFormComponents/ProBookingPhotoDuration";
// import ProBookingEventAddress from "./proBookingFormComponents/ProBookingEventAddress";
// import ProBookingEventDate from "./proBookingFormComponents/ProBookingEventDate";
// import ProBookingEventDetails from "./proBookingFormComponents/ProBookingEventDetails";
// import ProBookingEventTime from "./proBookingFormComponents/ProBookingEventTime";
// import ProBookingVideoDuration from "./proBookingFormComponents/ProBookingVideoDuration";

// Global imports
import ContactEmail from "../../assets/formInputComponents/ContactEmail";
import ContactNumber from "../../assets/formInputComponents/ContactNumber";
import ContactPerson from "../../assets/formInputComponents/ContactPerson";
import EventAddress from "../../assets/formInputComponents/EventAddress";
import EventDate from "../../assets/formInputComponents/EventDate";
import EventDetails from "../../assets/formInputComponents/EventDetails";
import EventTime from "../../assets/formInputComponents/EventTime";
import PhotoDuration from "../../assets/formInputComponents/PhotoDuration";
import VideoDuration from "../../assets/formInputComponents/VideoDuration";

import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../firebase";

export default function ProBookingForm({ profile }) {
  const [show, setShow] = useState(false);

  // const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [region, setRegion] = useState("");
  // const [regionError, setRegionError] = useState(false);

  const hostname = window.location.hostname;

  useEffect(() => {
    console.log(formData);
    if (formData.address) {
      setAddress(formData.address);
      setRegion(formData.region);
    }
  }, [formData]);

  useEffect(() => {
    if (address && region === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [address, region]);

  useEffect(() => {
    console.log("Address: ", address);
    console.log("Region: ", region);
    console.log("Address Error", addressError);
  }, [address, region, addressError]);

  const addData = useCallback((id, data) => {
    if (hostname === "localhost") {
      console.log("Local onChange: ", id, data);
    }

    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  const closeModal = () => {
    setShow(false);
    // console.log("close modal activated");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (hostname === "localhost") {
      console.log(formData);
    }

    const docRef = collection(db, "bookingDirects");

    try {
      await addDoc(docRef, {
        ...formData,
        submittedAt: serverTimestamp(),
        completed: false,
        professional: profile.name,
        user: profile.user,
        status: "just submitted",
      });
      toast.success("Your form has been submitted, we will be in touch.");
      setFormData("");
      document.getElementById("form").reset();
      closeModal();
      // console.log("After Submit: ", formData);
    } catch (error) {
      console.log(error.message);
      toast.error(
        "Something went wrong with your submission, please try again"
      );
    }
  };

  return (
    <>
      <div>
        <button
          onClick={() => setShow(true)}
          className="block mx-auto my-2 p-2 border rounded-md shadow text-white text-lg sm:text-xl bg-green-400 hover:bg-green-500 active:bg-green-600"
        >
          <GiShoppingCart className="inline-block mr-2" />
          Hire Professional
        </button>

        {/* <!-- Main modal --> */}
        <div
          className={`${
            show === true ? "" : "hidden"
          } absolute top-0 left-0 bg-black w-screen h-full bg-opacity-80`}
          onClick={closeModal}
        ></div>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`${
            show === true ? "" : "hidden"
          } absolute left-0 md:left-10 top-5 md:top-10 w-full md:w-4/5 z-10 p-4`}
        >
          <div className="relative w-full max-w-2xl max-h-full">
            {/* <!-- Modal content --> */}
            <div className="bg-white rounded-lg shadow">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-4 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Hire {profile.name}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={closeModal}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-gray-500">
                  Please fill out the following form in order to hire{" "}
                  {profile.name}. The BraThula team will get back to you to
                  finalise the order.
                  <br />
                  <br />
                  If you have any questions, please don't hesitate to email:
                  hello@brathula.com
                </p>
                <form onSubmit={onSubmit} id="form">
                  <EventDate addData={addData} />
                  <EventTime addData={addData} />
                  <EventDetails addData={addData} />
                  <EventAddress addData={addData} />
                  <ContactPerson addData={addData} />
                  <ContactEmail addData={addData} />
                  <ContactNumber addData={addData} />
                  {profile.photographer && (
                    <PhotoDuration addData={addData} profile={profile} />
                  )}
                  {profile.videographer && (
                    <VideoDuration addData={addData} profile={profile} />
                  )}
                  {/* <!-- Modal footer --> */}
                  <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                      disabled={addressError}
                      data-modal-hide="defaultModal"
                      className={`${
                        addressError
                          ? "bg-gray-300 cursor-not-allowed"
                          : "bg-orange-500 hover:bg-orange-600 active:bg-orange-700 cursor-pointer"
                      } m-4 p-2 rounded-lg`}
                    >
                      Place Order
                    </button>
                    <button
                      data-modal-hide="defaultModal"
                      disabled={addressError}
                      type="button"
                      onClick={closeModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                      Cancel
                    </button>
                  </div>
                  {addressError && (
                    <ul>
                      <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                        Please select a physical address from the options
                        provided once you start typing
                      </li>
                    </ul>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal toggle --> */}
    </>
  );
}
