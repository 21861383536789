import Battery from "../equipmentIcons/Battery";
import Lighting from "../equipmentIcons/Lighting";
import Flash from "../equipmentIcons/Flash";
import Tripod from "../equipmentIcons/Tripod";
import Backdrop from "../equipmentIcons/Backdrop";
import Air from "../equipmentIcons/Air";
import ElectricFan from "../equipmentIcons/ElectricFan";

export const photoEquipmentArray = (className) => [
  {
    id: "photoEquipment",
    title: "Extra Batteries",
    value: "extraBatteries",
    type: "checkbox",
    icon: <Battery className={className} />,
  },
  {
    id: "photoEquipment",
    title: "Lighting",
    value: "lighting",
    type: "checkbox",
    icon: <Lighting className={className} />,
  },
  {
    id: "photoEquipment",
    title: "External Flash",
    value: "externalFlash",
    type: "checkbox",
    icon: <Flash className={className} />,
  },
  {
    id: "photoEquipment",
    title: "Tripod",
    value: "tripod",
    type: "checkbox",
    icon: <Tripod className={className} />,
  },
  {
    id: "photoEquipment",
    title: "Backdrop",
    value: "backdrop",
    type: "checkbox",
    icon: <Backdrop className={className} />,
  },
  {
    id: "photoEquipment",
    title: "Smoke Machine",
    value: "smokeMachine",
    type: "checkbox",
    icon: <Air className={className} />,
  },
  {
    id: "photoEquipment",
    title: "Fan",
    value: "fan",
    type: "checkbox",
    icon: <ElectricFan className={className} />,
  },
];

// battery, lighting, sound equipment, stabilizer, tripod, external flash
