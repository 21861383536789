import React from "react";

export default function DoNotDisturb({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p">
          <g>
            <path d="M44,25H21v-5c0-5.318,2.89-11,11-11,5.418,0,9.143,2.584,10.488,7.276,.123,.429,.515,.724,.961,.724h6.313c.291,0,.567-.126,.758-.347,.189-.22,.274-.512,.231-.8C49.342,6.371,41.806,0,32,0,20.99,0,13,7.991,13,19V63c0,.553,.447,1,1,1H50c.553,0,1-.447,1-1v-31c0-3.86-3.141-7-7-7Zm5,37H15V19c0-9.851,7.149-17,17-17,8.509,0,14.896,5.062,16.568,13h-4.387c-1.767-5.096-6.164-8-12.182-8-7.897,0-13,5.103-13,13v6c0,.552,.447,1,1,1h24c2.757,0,5,2.243,5,5v30Z" />
            <path d="M32,32c-7.72,0-14,6.28-14,14s6.28,14,14,14,14-6.28,14-14-6.28-14-14-14Zm0,26c-6.617,0-12-5.383-12-12s5.383-12,12-12,12,5.383,12,12-5.383,12-12,12Z" />
            <path d="M26.681,37.539c-.257,.161-.427,.432-.461,.734-.033,.302,.072,.604,.287,.818l12.401,12.401c.188,.188,.443,.293,.707,.293,.037,0,.074-.002,.111-.006,.303-.034,.573-.204,.734-.461,2.503-3.974,1.932-9.068-1.39-12.391-3.322-3.321-8.417-3.894-12.391-1.39Zm12.684,11.582l-10.485-10.485c2.968-1.273,6.429-.643,8.778,1.707,2.35,2.351,2.979,5.811,1.707,8.778Z" />
            <path d="M25.092,40.507c-.215-.215-.521-.32-.818-.287-.303,.034-.573,.204-.734,.461-2.503,3.974-1.932,9.068,1.39,12.391,1.939,1.939,4.483,2.94,7.056,2.94,1.833,0,3.682-.509,5.335-1.551,.257-.161,.427-.432,.461-.734,.033-.302-.072-.604-.287-.818l-12.401-12.401Zm1.251,11.15c-2.35-2.351-2.979-5.811-1.707-8.778l10.485,10.485c-2.969,1.272-6.43,.643-8.778-1.707Z" />
          </g>
        </g>
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
