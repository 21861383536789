import React, { useState, useEffect } from "react";
// import { exampleImagesArray } from "./exampleImagesArray";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, storage } from "../../firebase";

export default function Gallery({ studio, studioDocReady }) {
  const [imageList, setImageList] = useState([]);
  const [imageIDs, setImageIDs] = useState([]);

  useEffect(() => {
    if (studioDocReady) {
      const colRef = collection(db, "imagesStudio");
      const q = query(colRef, where("user", "==", studio.user));

      onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs;
        setImageIDs(docs.map((doc) => doc.id));
        setImageList(docs.map((doc) => doc.data()));
      });
    }
  }, [studioDocReady]);

  // useEffect(() => {
  //   console.log("User: ", studio?.user);
  //   console.log("images: ", imageList);
  //   console.log("Studio Doc Ready? ", studioDocReady);
  // });

  return (
    <>
      <div className="h-36 md:h-60 overflow-x-scroll flex flex-row no-wrap mx-auto bg-orange-100">
        {imageList.map((item) => (
          <img
            className="mx-4 my-2 h-32 md:h-56 rounded-lg"
            src={item.imageURL}
            alt={item.imageName}
          />
        ))}
      </div>
    </>
  );
}
