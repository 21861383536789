import React, { useState, useEffect } from "react";
import { Carousel, IconButton } from "@material-tailwind/react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ImLocation } from "react-icons/im";

export default function ImagesGallery({
  packagesDocsReady,
  packageId,
  packageSolo,
}) {
  const [packageImages, setPackageImages] = useState([]);
  // const [navShow, setNavShow] = useState(false);
  const hostname = window.location.hostname;
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("Package Images", packageImages);
  // }, [packageImages]);

  // useEffect(() => {
  //   console.log("Show Navigation: ", navShow);
  // }, [navShow]);

  useEffect(() => {
    if (packagesDocsReady && packageSolo) {
      packageSolo.images.slice(0, 3).forEach((element) => {
        // console.log("element: ", element);
        // setPackageImages((items) => [
        //   ...items,
        //   element.replace(
        //     "https://storage.googleapis.com/",
        //     "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/"
        //   ),
        // ]);
        // setPackageImages((items) => [...items, element]);
        if (hostname === "localhost") {
          setPackageImages((items) => [...items, element]);
        } else {
          const imageKitUrl = element.replace(
            "https://storage.googleapis.com/",
            "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/"
          );
          setPackageImages((items) => [...items, imageKitUrl]);
        }
      });
    }
  }, [packagesDocsReady]); // eslint-disable-line

  return (
    <>
      <div className="h-3/4 md:h-4/5 rounded-md relative">
        <div
          // onMouseOver={showNav}
          // onMouseLeave={hideNav}
          className="h-full w-full relative"
        >
          <Carousel
            loop={true}
            autoplay={false}
            navigation={false}
            className="rounded-xl"
            prevArrow={({ handlePrev }) => (
              <IconButton
                variant="text"
                color="orange"
                size="lg"
                onClick={handlePrev}
                className="!absolute z-10 top-1/2 left-0 md:left-4 -translate-y-2/4 text-orange-400"
              >
                <RiArrowLeftSLine className="" />
              </IconButton>
            )}
            nextArrow={({ handleNext }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handleNext}
                className="!absolute z-10 top-1/2 md:!right-4 !right-0 -translate-y-2/4 text-orange-400"
              >
                <RiArrowRightSLine className="" />
              </IconButton>
            )}
          >
            {packageImages.map((item, index) => {
              // console.log(item);
              return (
                <>
                  <img
                    src={item}
                    alt={index + 1}
                    loading="lazy"
                    onClick={() => navigate(`/package/${packageId}`)}
                    className="md:p-1 rounded-md h-full w-auto object-cover object-center items-center text-center mx-auto"
                  />
                  {/* <span className="absolute bottom-0 left-0 bg-pink-400">
                    Hello
                  </span> */}
                </>
              );
            })}
          </Carousel>
          <div className="absolute rounded-md bg-opacity-90 bg-white flex left-1 bottom-1 p-1">
            <ImLocation className="text-orange-400 text-xs" />
            <span className="text-orange-400 text-xs">
              {packageSolo.profileRegion}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
