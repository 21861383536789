import React, { useEffect, useState } from "react";

export default function EventTime({ addData }) {
  const [hour, setHour] = useState("01");
  const [minute, setMinute] = useState("00");
  const [AMPM, setAMPM] = useState("PM");
  const [time, setTime] = useState();

  const onHourChange = (e) => {
    setHour(e.target.value);
  };

  const onMinuteChange = (e) => {
    setMinute(e.target.value);
  };

  const onAMPMChange = (e) => {
    setAMPM(e.target.value);
  };

  useEffect(() => {
    setTime(`${hour}:${minute} ${AMPM}`);
  }, [hour, minute, AMPM]);

  useEffect(() => {
    addData("startTime", time);
  }, [time, addData]);

  return (
    <>
      <div className="py-5 pl-4 sm:p-5 w-fit bg-white rounded-lg">
        <h1>Start Time</h1>
        <div className="flex">
          <select
            required
            name="hours"
            className="bg-transparent appearance-none outline-none"
            onChange={onHourChange}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <span className="mx-1">:</span>
          <select
            name="minutes"
            className="bg-transparent appearance-none outline-none mr-1"
            onChange={onMinuteChange}
          >
            <option value="0">00</option>
            <option value="30">30</option>
          </select>
          <select
            name="ampm"
            className="bg-transparent appearance-none outline-none"
            onChange={onAMPMChange}
          >
            <option value="PM">PM</option>
            <option value="AM">AM</option>
          </select>
        </div>
      </div>
    </>
  );
}
