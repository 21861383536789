import React from "react";

export default function Pregnancy({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.1"
        id="Layer_1"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        {/* <style type="text/css">
          {`.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
 .st1{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style> */}
        <g>
          <path
            d="M37.5,21.8c0,0,3.9,5.1,10.6,0.7c0,0-4.8-2-4.3-12.3c0.1-2.2-1-4.2-2.8-5.4l0,0c-5.5-3.6-12.6-3.6-18.1,0l0,0
   c-1.8,1.2-2.9,3.2-2.8,5.4c0.5,10.3-4.3,12.3-4.3,12.3c6.7,4.4,10.6-0.7,10.6-0.7"
          />
          <path
            d="M37.7,15h-1.1c-2.1,0-3.9-1.5-4.3-3.5v0c-0.1-0.7-0.7-1.2-1.4-1.2l-4.2,0c-1.2,0-2.1,0.9-2.1,2.1v4.4
   c0,1.9,0.7,3.7,1.9,5c1.4,1.5,3.3,2.4,5.5,2.4c2.2,0,4.1-0.9,5.5-2.4c0,0,0,0,0,0c1.2-1.3,1.9-3.1,1.9-5v0
   C39.4,15.8,38.6,15,37.7,15z"
          />
          <path d="M15.1,59.5c0,0-2.2-27.6,6.4-29.3c5-1,6.2-5.3,6.5-7.1c0,0,0,0,0,0" />
          <path d="M36.1,23c0.3,1.8,1.4,6.4,6.5,7.1C51,31.3,49,59.5,49,59.5" />
          <path
            d="M23.3,48.4c-0.2-1.5-1.1-9.4,1.4-12.2c1.7-1.9,4.2-1.1,5.9-0.2c1,0.5,2.2,0.5,3.1,0c1.6-0.9,4.1-1.7,5.8,0.2
   c2.5,2.8,1.6,10.6,1.3,12.2"
          />
          <line x1="21.5" y1="30.1" x2="24.6" y2="36.2" />
          <line x1="39.4" y1="36.2" x2="42.6" y2="30.1" />
          <path d="M25.3,46.7c0,0-5.4,2.2-3.5,10.9c0.5,2.4,3,4.3,5.4,4.3h9.6c2.4,0,4.9-1.9,5.4-4.3c1.9-8.7-3.5-10.9-3.5-10.9" />
        </g>
      </svg>
    </>
  );
}
