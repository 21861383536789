import React from "react";

export default function DogWithBone({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <path d="M21.9,9.5C27.8,6.1,36.5,6,42.3,9.4" />
          <path
            d="M51.3,31.5c1.4,9.9,0,19.2-13.6,14.7c-1.2-0.4-2.4-0.9-3.8-1.5c-0.6-0.3-1.3-0.4-2-0.4c-0.6,0-1.3,0.1-1.8,0.4
     c-1.4,0.6-2.7,1.1-3.9,1.5c-13.5,4.4-15-4.9-13.6-14.7"
          />
          <path
            d="M36.6,32.6c0,1.4-1.5,2.7-2.7,3.6c-1.1,0.8-2.6,0.8-3.8,0c-1.3-0.8-2.7-2.1-2.7-3.6c0-2.6,2.1-3.4,4.6-3.4
     S36.6,30.1,36.6,32.6z"
          />
          <line x1="32" y1="44.3" x2="32" y2="36.8" />
          <path d="M21.9,9.5c0,0-9.6-5-15.5,0s1.2,8.2,0,19.5c-0.3,3.2,5.5,5,9.2,0C18.8,24.9,16.2,17.6,21.9,9.5" />
          <path d="M42.1,9.5c0,0,9.6-5,15.5,0s-1.2,8.2,0,19.5c0.3,3.2-5.5,5-9.2,0C45.2,24.9,47.8,17.6,42.1,9.5" />
          <ellipse cx="24.3" cy="23" rx="2.4" ry="3.3" />
          <ellipse cx="39.7" cy="23" rx="2.4" ry="3.3" />
          <path
            d="M13.7,43.9h-1.8c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3c0,1.9,1.2,3.5,3,4.1h0h0c-1.7,0.5-3,2.2-3,4.1
     c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3h40.1c0.1,2.3,2,4.1,4.3,4.1c2.4,0,4.3-1.9,4.3-4.3c0-1.9-1.2-3.5-3-4.1
     c1.7-0.6,3-2.2,3-4.1c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3c0,0-0.6,0-1.6,0"
          />
          <path d="M47,52.1L47,52.1c-2.5,3.2-6.3,5-10.4,5h-9.3c-4.1,0-7.9-1.9-10.4-5l0,0" />
        </g>
      </svg>
    </>
  );
}
