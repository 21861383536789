import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";

export default function Address({ studio, studioDocReady }) {
  const circle1Options = {
    fillColor: "#fa9204",
    fillOpacity: 0.5,
    strokeWeight: 1,
    strokeColor: "white",
    clickable: false,
    editable: false,
    zIndex: 5,
  };

  const circle2Options = {
    fillColor: "blue",
    fillOpacity: 0.3,
    strokeWeight: 1,
    strokeColor: "white",
    clickable: false,
    editable: false,
    zIndex: 1,
  };

  const [center, setCenter] = useState();

  useEffect(() => {
    if (studioDocReady) {
      setCenter({
        lat: studio.coordinates.lat,
        lng: studio.coordinates.lng,
      });
    }
  }, [studio, studioDocReady]);

  // useEffect(() => {
  //   console.log("Center: ", center);
  //   console.log("Studio Ready", studioDocReady);
  // }, [center, studioDocReady]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_KEY,
  });

  const [map, setMap] = useState(null);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <div className="flex my-1">
        <GoogleMap
          className="mx-auto"
          mapContainerStyle={{ width: "100vw", height: "auto" }}
          center={center}
          zoom={13}
          onLoad={(map) => setMap(map)}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            streetView: false,
            fullscreenControl: false,
            zoomControl: false,
          }}
        >
          {/* <Marker position={center} /> */}
          <Circle center={center} radius={1000} options={circle1Options} />
          {/* <Circle center={center} radius={5000} options={circle2Options} /> */}
          <p className="mt-5 font-bold italic inline-block">
            Address will be shown once the booking is confirmed.
          </p>
        </GoogleMap>
      </div>
    </>
  ) : (
    <></>
  );
}
