import ToiletPaper from "../studioAmenityIcons/ToiltetPaper";
import Mirror from "../studioAmenityIcons/Mirror";
import Sofa from "../studioAmenityIcons/Sofa";
import Water from "../studioAmenityIcons/Water";
import HandsWashing from "../studioAmenityIcons/HandsWashing";
import Wifi from "../studioAmenityIcons/Wifi";
import DoNotDisturb from "../studioAmenityIcons/DoNotDisturb";
import ClassicCar from "../studioAmenityIcons/ClassicCar";
import Lollipop from "../studioAmenityIcons/Lollipop";
import PetInsurance from "../studioAmenityIcons/PetInsurance";
import SolarCell from "../studioAmenityIcons/SolarCell";

export const studioAmenityArray = (className) => [
  {
    id: "amenities",
    title: "Rest Room",
    value: "restRoom",
    icon: <HandsWashing className={className} />,
  },
  {
    id: "amenities",
    title: "Change Room",
    value: "changeRoom",
    icon: <Mirror className={className} />,
  },
  {
    id: "amenities",
    title: "Waiting Area",
    value: "waitingArea",
    icon: <Sofa className={className} />,
  },
  {
    id: "amenities",
    title: "Beverage Station",
    value: "beverageStation",
    icon: <Water className={className} />,
  },
  {
    id: "amenities",
    title: "Free Wifi",
    value: "freeWifi",
    icon: <Wifi className={className} />,
  },
  {
    id: "amenities",
    title: "Private Shoot Area",
    value: "privateShoot",
    icon: <DoNotDisturb className={className} />,
  },
  {
    id: "amenities",
    title: "Parking",
    value: "parking",
    icon: <ClassicCar className={className} />,
  },
  {
    id: "amenities",
    title: "Child Friendly",
    value: "childFriendly",
    icon: <Lollipop className={className} />,
  },
  {
    id: "amenities",
    title: "Pet Friendly",
    value: "petFriendly",
    icon: <PetInsurance className={className} />,
  },
  {
    id: "amenities",
    title: "Backup Power",
    value: "backupPower",
    icon: <SolarCell className={className} />,
  },
];
