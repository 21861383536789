import React from "react";

export default function Test() {
  return (
    <>
      <div
        onClick={() => console.log("green")}
        className="relative h-96 w-96 bg-green-500 z-0"
      >
        <div
          onClick={() => console.log("Amber")}
          className="absolute h-40 w-40 z- bg-amber-500 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        ></div>
      </div>
    </>
  );
}
