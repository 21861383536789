import React, { useEffect, useState } from "react";
// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";
import { Carousel, IconButton } from "@material-tailwind/react";

export default function Gallery({ packageShow, packageDocReady }) {
  const [packageImages, setPackageImages] = useState([]);

  useEffect(() => {
    if (packageDocReady) {
      packageShow.images.forEach((element) => {
        // console.log(element);
        setPackageImages((items) => [...items, { original: element }]);
      });
    }
  }, [packageDocReady]); // eslint-disable-line

  return (
    <>
      <div className="my-5 h-80 md:h-96">
        <Carousel
          loop={true}
          autoplay={false}
          className="rounded-xl"
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="orange"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 left-0 md:left-4 -translate-y-2/4 text-orange-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 md:!right-4 !right-0 -translate-y-2/4 text-orange-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </IconButton>
          )}
        >
          {packageImages.map((item, index) => {
            return (
              <img
                src={item.original}
                alt={index + 1}
                loading="lazy"
                className="rounded-md h-full w-auto object-contain mx-auto"
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
}
