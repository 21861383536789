import React from "react";

export default function PostShoot({ packageShow, packageDocReady, heading }) {
  return (
    <>
      {packageDocReady && (
        <div className=" my-5">
          <p className={heading}>Post-Shoot</p>
          <div className="mx-2 md:mx-0 grid md:grid-cols-2">
            <div>No. Images: {packageShow.noImages}</div>
            {/* <div>Images: {packageShow.shootImages}</div> */}
            {packageShow.shootImages === "edited_and_unedited" && (
              <div>Images: Edited and Unedited Images</div>
            )}
            {packageShow.shootImages === "unedited_images" && (
              <div>Images: Unedited Images</div>
            )}
            {packageShow.shootImages === "edited_images" && (
              <div>Images: Edited Images</div>
            )}
            {packageShow.noVideos && (
              <div>No. Videos: {packageShow.noVideos}</div>
            )}
            {packageShow.videoDuration && (
              <div>Video Duration: {packageShow.videoDuration}min</div>
            )}
            {packageShow.deliveryTime > 24 ? (
              <div>Delivery Time: {packageShow.deliveryTime / 24} days</div>
            ) : (
              <div>Delivery Time: {packageShow.deliveryTime}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
