import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

//components
import Address from "../components/studioComponents/Address";
import Amenities from "../components/studioComponents/Amenities";
import Gallery from "../components/studioComponents/Gallery";
import GeneralInfo from "../components/studioComponents/GeneralInfo";
import PhotoCams from "../components/studioComponents/PhotoCams";
import PhotoEquipment from "../components/studioComponents/PhotoEquipment";
import Price from "../components/studioComponents/Price";
import Props from "../components/studioComponents/Props";
import VideoCams from "../components/studioComponents/VideoCams";
import VideoEquipment from "../components/studioComponents/VideoEquipment";
import Name from "../components/studioComponents/Name";

export default function Studio() {
  const hostname = window.location.hostname;
  const params = useParams();
  const { docId } = params;

  const [studio, setStudio] = useState();
  const [studioDocReady, setStudioDocReady] = useState(false);
  const [profile, setProfile] = useState();

  //icon classes
  // const iconClass = "w-8 h-8 fill-current text-orange-400";
  const iconClass = {
    artist1: "w-8 h-8 fill-current text-orange-400",
    artist2: "w-8 h-8 fill-none stroke-orange-400 stroke-2",
  };
  const containerClass = "m-2 flex items-center";
  const lineItemClass = "py-2 underline decoration-2 decoration-orange-400";

  useEffect(() => {
    const docRef = doc(db, "studios", docId);
    getDoc(docRef).then((snapshot) => {
      setStudio(snapshot.data());
      setStudioDocReady(true);
    });
  }, [docId]);

  useEffect(() => {
    if (studioDocReady) {
      const docRef = doc(db, "profiles", studio.user);
      getDoc(docRef).then((snapshot) => {
        setProfile(snapshot.data());
      });
    }
  }, [studioDocReady, studio]);

  useEffect(() => {
    if (studioDocReady && hostname === "localhost") {
      console.log("Studio: ", studio);
      console.log("Studio:", docId);
      console.log("Profile: ", profile);
    }
  }, [studio, docId, studioDocReady, hostname, profile]);

  return (
    <>
      <div className="">
        <Name studio={studio} />
        <div className="md:w-4/5 mx-auto divide-y divide-gray-200">
          <Gallery studio={studio} studioDocReady={studioDocReady} />
          <div className="grid md:grid-cols-2 my-2">
            <GeneralInfo
              studio={studio}
              studioDocReady={studioDocReady}
              profile={profile}
            />
            <Address studio={studio} studioDocReady={studioDocReady} />
          </div>
          <Props
            studio={studio}
            iconClass={iconClass}
            containerClass={containerClass}
            lineItemClass={lineItemClass}
            studioDocReady={studioDocReady}
          />

          <Amenities
            studio={studio}
            iconClass={iconClass}
            containerClass={containerClass}
            lineItemClass={lineItemClass}
            studioDocReady={studioDocReady}
          />

          {studio.photoCams.length === 0 ? (
            ""
          ) : (
            <>
              <PhotoCams
                studio={studio}
                iconClass={iconClass}
                containerClass={containerClass}
                lineItemClass={lineItemClass}
                studioDocReady={studioDocReady}
              />
              <PhotoEquipment
                studio={studio}
                iconClass={iconClass}
                containerClass={containerClass}
                lineItemClass={lineItemClass}
                studioDocReady={studioDocReady}
              />
            </>
          )}

          {studio.videoCams.length === 0 ? (
            ""
          ) : (
            <>
              <VideoCams
                studio={studio}
                iconClass={iconClass}
                containerClass={containerClass}
                lineItemClass={lineItemClass}
                studioDocReady={studioDocReady}
              />
              <VideoEquipment
                studio={studio}
                iconClass={iconClass}
                containerClass={containerClass}
                lineItemClass={lineItemClass}
                studioDocReady={studioDocReady}
              />
            </>
          )}
        </div>
        <Price studio={studio} studioDocReady={studioDocReady} />
      </div>
    </>
  );
}
