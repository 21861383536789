import React from "react";

export default function HandsWashing({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a">
          <g>
            <path d="M45,62c-1.41,0-2.803-.21-4.142-.624-.214-.067-.446-.058-.655,.022-2.662,1.024-5.654,.702-8.023-.811-.317-.203-.726-.209-1.052-.015-1.558,.934-3.331,1.427-5.127,1.427-5.514,0-10-4.486-10-10,0-.636,.211-2,.211-2,.326-1.594,1.03-3.099,2.074-4.363,.352-.426,.292-1.056-.134-1.408-.425-.351-1.056-.291-1.408,.134-.933,1.13-1.627,2.424-2.091,3.798-2.306-2.073-3.652-5.03-3.652-8.161,0-6.065,4.935-11,11-11,2.083,0,4.119,.595,5.888,1.72,.269,.171,.602,.204,.899,.088,.296-.115,.521-.365,.604-.672,1.29-4.791,5.653-8.137,10.609-8.137,1.599,0,3.141,.335,4.583,.997,.5,.229,1.096,.009,1.326-.492,.23-.502,.01-1.096-.492-1.326-1.705-.783-3.528-1.179-5.417-1.179-5.43,0-10.256,3.396-12.148,8.4-1.813-.919-3.813-1.4-5.852-1.4-7.168,0-13,5.832-13,13,0,4.055,1.902,7.867,5.12,10.322-.08,.562-.12,1.124-.12,1.678,0,6.617,5.383,12,12,12,1.958,0,3.89-.487,5.626-1.412,2.705,1.524,6,1.836,8.969,.797,1.429,.408,2.909,.615,4.404,.615,.552,0,1-.448,1-1s-.448-1-1-1Z" />
            <path d="M54,15c2.757,0,5-2.243,5-5s-2.243-5-5-5-5,2.243-5,5,2.243,5,5,5Zm0-8c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Z" />
            <path d="M8,52c-2.206,0-4,1.794-4,4s1.794,4,4,4,4-1.794,4-4-1.794-4-4-4Zm0,6c-1.103,0-2-.897-2-2s.897-2,2-2,2,.897,2,2-.897,2-2,2Z" />
            <path d="M10,30c0-2.206-1.794-4-4-4s-4,1.794-4,4,1.794,4,4,4,4-1.794,4-4Zm-6,0c0-1.103,.897-2,2-2s2,.897,2,2-.897,2-2,2-2-.897-2-2Z" />
            <path d="M59.826,30.213l-7.579-5.997c-.177-.14-.396-.216-.621-.216h-12.626c-.334,0-.647,.167-.833,.446-.186,.279-.219,.631-.09,.94l1.664,3.976c.015,.035,.031,.069,.049,.102,.53,.948,1.533,1.536,2.619,1.536h5.74l2.5,2H27c-1.598,0-3,1.402-3,3s1.402,3,3,3h16.5c.132,0,.41,.292,.488,.5-.078,.208-.356,.5-.488,.5H23c-1.598,0-3,1.402-3,3s1.402,3,3,3h20.5c.132,0,.41,.292,.488,.5-.078,.208-.356,.5-.488,.5H27c-1.598,0-3,1.402-3,3s1.402,3,3,3h16.5c.132,0,.41,.292,.488,.5-.078,.208-.356,.5-.488,.5h-10.5c-1.598,0-3,1.402-3,3s1.402,3,3,3h24c.234,0,.46-.082,.64-.232l2.402-2.001c2.516-2.096,3.958-5.176,3.958-8.45v-10.477c0-3.383-1.521-6.527-4.174-8.626Zm2.174,19.104c0,2.679-1.18,5.199-3.238,6.914l-2.124,1.77h-23.638c-.554,0-1-.547-1-1s.446-1,1-1h10.5c1.426,0,2.5-1.517,2.5-2.5s-1.074-2.5-2.5-2.5H27c-.554,0-1-.547-1-1s.446-1,1-1h16.5c1.426,0,2.5-1.517,2.5-2.5s-1.074-2.5-2.5-2.5H23c-.554,0-1-.547-1-1s.446-1,1-1h20.5c1.426,0,2.5-1.517,2.5-2.5s-1.074-2.5-2.5-2.5H27c-.554,0-1-.547-1-1s.446-1,1-1h23.649c.861,0,1.602-.525,1.887-1.338,.285-.812,.035-1.686-.638-2.223l-2.774-2.219c-.177-.142-.397-.219-.625-.219h-6.091c-.346,0-.667-.179-.849-.471l-1.058-2.529h10.776l7.306,5.781c2.17,1.718,3.416,4.291,3.416,7.058v10.477Z" />
            <path d="M8,17v-1h7v2h-2c-.552,0-1,.448-1,1v4c0,.552,.448,1,1,1H29c.552,0,1-.448,1-1v-4c0-.552-.448-1-1-1h-2v-7c0-3.86-3.14-7-7-7H8v-1c0-1.654-1.346-3-3-3H1C.448,0,0,.448,0,1V19c0,.552,.448,1,1,1H5c1.654,0,3-1.346,3-3Zm20,5H14v-2h14v2ZM20,6c2.757,0,5,2.243,5,5v7h-8v-3c0-.552-.448-1-1-1H8V6h12ZM2,2h3c.551,0,1,.449,1,1v14c0,.551-.449,1-1,1H2V2Z" />
            <path d="M10,9h5c.552,0,1-.448,1-1s-.448-1-1-1h-5c-.552,0-1,.448-1,1s.448,1,1,1Z" />
            <rect x="17" y="7" width="2" height="2" />
          </g>
        </g>
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
