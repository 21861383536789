import React from "react";

export default function Lipstick({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <g>
            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 0.1002 16.4288)"
              cx="19.9"
              cy="8.1"
              rx="7.4"
              ry="2.8"
            />
            <line x1="25.4" y1="3.9" x2="25.4" y2="23.3" />
            <line x1="14.3" y1="12.2" x2="14.3" y2="23.3" />
          </g>
          <path d="M25.5,33.8H12.3v-8.9c0-0.9,0.7-1.6,1.6-1.6h0.4h11.1h0.4c0.9,0,1.6,0.7,1.6,1.6V32" />
          <path d="M25.5,33.8H12.3H12c-0.8,0-1.4,0.6-1.4,1.4V38h12.2" />
          <path d="M29.2,58.4c-0.1,1.7-1.6,3-3.3,3h-12c-1.8,0-3.3-1.5-3.3-3.3V38h12.2" />
          <circle cx="37.4" cy="44.6" r="16.1" />
          <path
            d="M33.9,48.1L33.9,48.1c0,1.9,1.5,3.4,3.4,3.4h0.1c1.9,0,3.4-1.5,3.4-3.4v0c0-1.9-1.5-3.4-3.4-3.4h-0.1
     c-1.9,0-3.4-1.5-3.4-3.4v0c0-1.9,1.5-3.4,3.4-3.4h0.1c1.9,0,3.4,1.5,3.4,3.4v0"
          />
          <line x1="37.4" y1="37.7" x2="37.4" y2="34.5" />
          <line x1="37.4" y1="54.7" x2="37.4" y2="51.5" />
        </g>
      </svg>
    </>
  );
}
