import React from "react";
import PropIcons from "./studioListComponents/PropIcons";

export default function studioList({ studios }) {
  return (
    <div className="flex">
      {studios && (
        <div className="mx-auto mt-2 w-full md:w-10/12 lg:3/4">
          <div className="grid flex-col gap-3 m-2 md:grid-cols-2 p-1">
            {studios.map((studio) => {
              return (
                <div
                  key={studio.user}
                  className="w-full flex flex-row items-center bg-gray-300 hover:bg-gray-400 border rounded-lg shadow cursor-pointer"
                  // onClick={() => navigate(`/portfolio/${profile.user}`)}
                >
                  <p>{studio.name}</p>
                  <PropIcons studio={studio} />

                  {/* <ListingImage profile={profile} /> */}
                  {/* <ListingDetails profile={profile} /> */}
                  {/* <GeoDetails
                  profile={profile}
                  userAddress={userAddress}
                  handleRouteDistance={handleRouteDistance}
                />
                <TravelCost profile={profile} routeDistance={routeDistance} /> */}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
