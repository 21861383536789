import React from "react";

export default function Mask({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        version="1.0"
        id="Layer_1"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        <style type="text/css">
          {`.st0{fill:none;stroke:#fb923c;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}`}
        </style>
        <g>
          <path d="M26.9,21.6c0,0,1.4-4.4-4.5-4.4s-10.1-2.1-10.1-2.1s-1.5,6.2,2.7,9.2C19.8,27.8,25.2,25.4,26.9,21.6z" />
          <path
            d="M32,19.2c0,0,0.2-6.7,10.2-7.5C52.7,11,56.9,4.3,56.9,4.3S59,10.1,58,14.4c-0.3,1.4,1.4,2.6,2.5,1.7
     c0.1-0.1,0.2-0.1,0.2-0.2c0,0,2.2,12.8-10.6,15.2s-18-2.8-18-2.8s-5.2,5.2-18,2.8S3.3,15.8,3.3,15.8C3.4,15.9,3.5,16,3.5,16
     C4.7,17,6.4,15.8,6,14.4C5,10.1,7.1,4.3,7.1,4.3s4.2,6.7,14.7,7.5C31.8,12.5,32,19.2,32,19.2z"
          />
          <path d="M37.1,21.6c0,0-1.4-4.4,4.5-4.4s10.1-2.1,10.1-2.1s1.5,6.2-2.7,9.2C44.2,27.8,38.8,25.4,37.1,21.6z" />
          <path d="M57.2,27.6v30.7c0,0.8-0.7,1.5-1.5,1.5l0,0c-0.8,0-1.5-0.7-1.5-1.5V29.7" />
        </g>
      </svg>
    </>
  );
}
