import React from "react";

export default function Availability({ studio }) {
  return (
    <>
      {studio?.availability === "7Days" && (
        <p className="my-1">
          <span className="font-bold">Availability:</span> 7 Days a week
        </p>
      )}
      {studio?.availability === "weekdays" && (
        <p className="my-1">
          <span className="font-bold">Availability:</span> Weekdays
        </p>
      )}
      {studio?.availability === "weekdays" && (
        <p className="my-1">
          <span className="font-bold">Availability:</span> Weekends
        </p>
      )}
    </>
  );
}
