import React from "react";

export default function Sofa({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        id="_21-sofa"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path d="M59.051,38c-.239-.02-1.019-.061-2.051,.161v-5.161c0-7.168-5.832-13-13-13H20c-7.168,0-13,5.832-13,13v5.161c-1.033-.222-1.814-.18-2.051-.161-2.734,.027-4.949,2.26-4.949,5,0,2.549,1.917,4.658,4.385,4.962,.578,1.124,2.02,4.268,2.474,8.445,.199,1.833,1.592,3.253,3.346,3.534l.825,3.301c.111,.445,.511,.758,.97,.758h4c.459,0,.859-.312,.97-.758l.811-3.242h28.438l.811,3.242c.111,.445,.511,.758,.97,.758h4c.459,0,.859-.312,.97-.758l.825-3.301c1.754-.28,3.147-1.701,3.346-3.534,.454-4.178,1.896-7.321,2.474-8.445,2.468-.304,4.385-2.413,4.385-4.962,0-2.74-2.215-4.973-4.949-5Zm-50.051-5c0-6.065,4.935-11,11-11h24c6.065,0,11,4.935,11,11v5.886c-.515,.261-1.041,.58-1.563,1.013-2.446,2.026-3.91,5.422-4.359,10.102H14.923c-.449-4.68-1.914-8.075-4.359-10.102-.522-.433-1.049-.751-1.563-1.012v-5.886Zm6.219,29h-2.438l-.5-2h3.438l-.5,2Zm36,0h-2.438l-.5-2h3.438l-.5,2Zm7.781-16c-.351,0-.677,.185-.857,.485-.097,.161-2.37,4.003-2.989,9.705-.112,1.032-.966,1.81-1.987,1.81H10.833c-1.021,0-1.875-.777-1.987-1.81-.62-5.702-2.893-9.544-2.989-9.705-.181-.301-.506-.485-.857-.485-1.654,0-3-1.346-3-3s1.346-3,3-3c.036,0,.079-.003,.115-.007,.019-.002,.126-.013,.304-.013,.666,0,2.313,.152,3.904,1.488,2.144,1.8,3.381,5.029,3.679,9.597,.035,.525,.471,.935,.998,.935H50c.527,0,.963-.409,.998-.935,.298-4.567,1.535-7.797,3.679-9.597,2.014-1.691,4.12-1.484,4.202-1.477,.04,.005,.081,.008,.122,.008,1.654,0,3,1.346,3,3s-1.346,3-3,3Z" />
        <path d="M30,28h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
        <path d="M18,28h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
        <path d="M42,28h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
        <path d="M34,38h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1Z" />
        <path d="M18,40h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
        <path d="M46,38h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1Z" />
        <path d="M24,34h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
        <path d="M36,34h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
        <path d="M28,44h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1Z" />
        <path d="M40,44h-1v-1c0-.553-.448-1-1-1s-1,.447-1,1v1h-1c-.552,0-1,.447-1,1s.448,1,1,1h1v1c0,.553,.448,1,1,1s1-.447,1-1v-1h1c.552,0,1-.447,1-1s-.448-1-1-1Z" />
      </svg>
    </>
  );
}
