// Software
import React, { useCallback, useState, useEffect } from "react";
import {
  serverTimestamp,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";

// Components
import StudioAddress from "./studioDetailsComponents/StudioAddress";
import StudioAmenities from "./studioDetailsComponents/StudioAmenities";
import StudioAppConfirm from "./studioDetailsComponents/StudioAppConfirm";
import StudioAvailability from "./studioDetailsComponents/StudioAvailability";
import StudioBasePrice from "./studioDetailsComponents/StudioBasePrice";
import StudioDescription from "./studioDetailsComponents/StudioDescription";
import StudioName from "./studioDetailsComponents/StudioName";
import StudioPhotoCams from "./studioDetailsComponents/StudioPhotoCams";
import StudioPhotoEquipment from "./studioDetailsComponents/StudioPhotoEquipment";
import StudioProps from "./studioDetailsComponents/StudioProps";
import StudioSize from "./studioDetailsComponents/StudioSize";
import StudioType from "./studioDetailsComponents/StudioType";
import StudioVideoCams from "./studioDetailsComponents/StudioVideoCams";
import StudioVideoEquipment from "./studioDetailsComponents/StudioVideoEquipment";
import StudioPublish from "./studioDetailsComponents/StudioPublish";
import StudioServices from "./studioDetailsComponents/StudioServices";

// Other components
import Loading from "../Loading";
import { toast } from "react-toastify";

export default function StudioDetails({ profile, studio, studioId, docReady }) {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //Fields that need error monitoring
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("");
  const [props, setProps] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [services, setServices] = useState([]);
  const [photoCams, setPhotoCams] = useState([]);
  const [photoEquipment, setPhotoEquipment] = useState([]);
  const [videoCams, setVideoCams] = useState([]);
  const [videoEquipment, setVideoEquipment] = useState([]);

  //Error fields
  const [addressError, setAddressError] = useState(false);
  const [propsError, setPropsError] = useState(false);
  const [amenitiesError, setAmenitiesError] = useState(false);
  const [servicesError, setServicesError] = useState(false);
  const [photoCamsError, setPhotoCamsError] = useState(false);
  const [photoEquipError, setPhotoEquipError] = useState(false);
  const [videoCamsError, setVideoCamsError] = useState(false);
  const [videoEquipError, setVideoEquipError] = useState(false);

  const hostname = window.location.hostname;

  //Assign filled in data to potential error fields
  useEffect(() => {
    if (formData.address) {
      setAddress(formData.address);
      setRegion(formData.region);
    }
    if (formData.props) {
      setProps(formData.props);
    }
    if (formData.amenities) {
      setAmenities(formData.amenities);
    }
    if (formData.services) {
      setServices(formData.services);
    }
    if (formData.photoCams) {
      setPhotoCams(formData.photoCams);
    }
    if (formData.photoEquipment) {
      setPhotoEquipment(formData.photoEquipment);
    }
    if (formData.videoCams) {
      setVideoCams(formData.videoCams);
    }
    if (formData.videoEquipment) {
      setVideoEquipment(formData.videoEquipment);
    }
  }, [formData]);

  useEffect(() => {
    if (address && region === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [address, region]);

  //Conditions for error
  useEffect(() => {
    if (services.length === 0) {
      setServicesError(true);
    } else {
      setServicesError(false);
    }
  }, [services]);

  useEffect(() => {
    if (services.length > 0 && props.length === 0) {
      setPropsError(true);
    } else {
      setPropsError(false);
    }
  }, [props, services]);

  useEffect(() => {
    if (services.length > 0 && amenities.length === 0) {
      setAmenitiesError(true);
    } else {
      setAmenitiesError(false);
    }
  }, [amenities, services]);

  useEffect(() => {
    if (services.includes("photography") && photoCams.length === 0) {
      setPhotoCamsError(true);
    } else {
      setPhotoCamsError(false);
    }
  }, [services, photoCams]);

  useEffect(() => {
    if (services.includes("photography") && photoEquipment.length === 0) {
      setPhotoEquipError(true);
    } else {
      setPhotoEquipError(false);
    }
  }, [services, photoEquipment]);

  useEffect(() => {
    if (services.includes("videography") && videoCams.length === 0) {
      setVideoCamsError(true);
    } else {
      setVideoCamsError(false);
    }
  }, [services, videoCams]);

  useEffect(() => {
    if (services.includes("videography") && videoEquipment.length === 0) {
      setVideoEquipError(true);
    } else {
      setVideoEquipError(false);
    }
  }, [services, videoEquipment]);

  useEffect(() => {
    if (address && region === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [address, region]);

  // function that will be used to add key/value pair
  const addData = useCallback((id, data) => {
    if (hostname === "localhost") {
      console.log("local print: ", id, data);
    }
    // console.log(id, data);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  // empties every associated if "photographer" or "videographer" are empty
  useEffect(() => {
    if (formData) {
      if (!services.includes("photography")) {
        // delete formData.photographerType;
        delete formData.photoCams;
        delete formData.photoEquipment;
      }
      if (!services.includes("videography")) {
        // delete formData.videographerType;
        delete formData.videoCams;
        delete formData.videoEquipment;
      }
    }
  }, [services]);

  useEffect(() => {
    console.log("formData: ", formData);
  }, [formData]);

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log("Studio Info: ", formData);

    setIsLoading(true);

    // if a studio exists already
    if (studio) {
      try {
        const updateRef = doc(db, "studios", studioId);
        await updateDoc(updateRef, {
          ...formData,
          updatedAt: serverTimestamp(),
        });
        toast.success("Studio Details Updated");
        setIsLoading(false);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        toast.error("There was an error updating your studio");
        setIsLoading(false);
        return;
      }
    } else {
      // if a studio does not exist
      try {
        await addDoc(collection(db, "studios"), {
          ...formData,
          user: profile.user,
          adminPublish: false,
          createdAt: serverTimestamp(),
        });
        toast.success("Studio Submission Successful");
        setIsLoading(false);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        toast.error("There was an error loading your studio");
        setIsLoading(false);
        return;
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="w-11/12 md:w-3/4 mx-auto shadow-lg mb-5">
            {/* <h1 className="mt-2 ml-4 text-2xl">Studio Details</h1> */}
            <form onSubmit={onSubmit}>
              <StudioName
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
              />
              <StudioAppConfirm
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
              />
              <StudioSize
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
              />
              <StudioServices
                addData={addData}
                studio={studio}
                docReady={docReady}
                servicesError={servicesError}
              />

              <div className="flex flex-wrap gap-x-16">
                <StudioType
                  studio={studio}
                  profile={profile}
                  addData={addData}
                  docReady={docReady}
                />
                <StudioAvailability
                  studio={studio}
                  profile={profile}
                  addData={addData}
                  docReady={docReady}
                />
              </div>

              <StudioAddress
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
                addressError={addressError}
              />
              {services.includes("photography") && (
                <StudioProps
                  studio={studio}
                  profile={profile}
                  addData={addData}
                  docReady={docReady}
                  propsError={propsError}
                />
              )}

              <StudioAmenities
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
                amenitiesError={amenitiesError}
              />
              <div className="flex flex-wrap">
                {services.includes("photography") && (
                  <>
                    <StudioPhotoCams
                      studio={studio}
                      profile={profile}
                      addData={addData}
                      docReady={docReady}
                      photoCamsError={photoCamsError}
                    />
                    <StudioPhotoEquipment
                      studio={studio}
                      profile={profile}
                      addData={addData}
                      docReady={docReady}
                      photoEquipError={photoEquipError}
                    />
                  </>
                )}
                {services.includes("videography") && (
                  <>
                    {" "}
                    <StudioVideoCams
                      studio={studio}
                      profile={profile}
                      addData={addData}
                      docReady={docReady}
                      videoCamsError={videoCamsError}
                    />
                    <StudioVideoEquipment
                      studio={studio}
                      profile={profile}
                      addData={addData}
                      docReady={docReady}
                      videoEquipError={videoEquipError}
                    />
                  </>
                )}
              </div>

              <StudioDescription
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
              />
              <StudioBasePrice
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
              />
              <StudioPublish
                studio={studio}
                profile={profile}
                addData={addData}
                docReady={docReady}
              />

              <button
                disabled={
                  addressError ||
                  propsError ||
                  amenitiesError ||
                  servicesError ||
                  photoCamsError ||
                  photoEquipError ||
                  videoCamsError ||
                  videoEquipError
                }
                // className="text-lg text-white p-2 m-2 shadow bg-orange-500 hover:bg-orange-600 active:bg-orange-700 cursor-pointer"
                className={`${
                  addressError ||
                  propsError ||
                  amenitiesError ||
                  servicesError ||
                  photoCamsError ||
                  photoEquipError ||
                  videoCamsError ||
                  videoEquipError
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-orange-500 hover:bg-orange-600 active:bg-orange-700 cursor-pointer"
                } m-4 p-2`}
              >
                Submit Studio Details
              </button>
              {addressError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please select a physical address from the option provided
                    once you start typing
                  </li>
                </ul>
              )}
              {propsError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please select at least one prop
                  </li>
                </ul>
              )}
              {amenitiesError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please select at least one amenity
                  </li>
                </ul>
              )}
              {servicesError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please specify if your studio does photography or
                    videography
                  </li>
                </ul>
              )}
              {photoCamsError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please choose at least one photo camera
                  </li>
                </ul>
              )}
              {photoEquipError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please choose photography equipment
                  </li>
                </ul>
              )}
              {videoCamsError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please choose at least one video camera
                  </li>
                </ul>
              )}
              {videoEquipError && (
                <ul>
                  <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                    Please choose video equipment
                  </li>
                </ul>
              )}
            </form>
          </div>
        </>
      )}
    </>
  );
}

// const [addressError, setAddressError] = useState(false);
//   const [propsError, setPropsError] = useState(false);
//   const [amenitiesError, setAmenitiesError] = useState(false);
//   const [servicesError, setServicesError] = useState(false);
//   const [photoCamsError, setPhotoCamsError] = useState(false);
//   const [photoEquipError, setPhotoEquipError] = useState(false);
//   const [videoCamsError, setVideoCamsError] = useState(false);
//   const [videoEquipError, setVideoEquipError] = useState(false);
