import React from "react";

export default function Mirror({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        id="_16-mirror"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path d="M56,24h-4.126C50.764,10.519,42.277,0,32,0S13.236,10.519,12.126,24h-4.126c-.553,0-1,.448-1,1V62c0,1.103,.897,2,2,2h2c1.103,0,2-.897,2-2v-2H51v2c0,1.103,.897,2,2,2h2c1.103,0,2-.897,2-2V25c0-.552-.447-1-1-1Zm-1,2v2h-3.019c.009-.332,.019-.665,.019-1s-.01-.668-.019-1h3.019ZM32,2c9.925,0,18,11.215,18,25s-8.075,25-18,25S14,40.785,14,27,22.075,2,32,2ZM9,26h3.019c-.009,.332-.019,.665-.019,1s.01,.668,.019,1h-3.019v-2Zm4,32v-2H51v2H13Zm40,4v-24c0-.553-.447-1-1-1s-1,.447-1,1v16H13v-16c0-.553-.447-1-1-1s-1,.447-1,1v24h-2V30h3.126c1.109,13.481,9.597,24,19.874,24s18.764-10.519,19.874-24h3.126V62h-2Z" />
        <path d="M32,50c8.673,0,16-10.532,16-23S40.673,4,32,4,16,14.533,16,27s7.327,23,16,23Zm0-44c7.589,0,14,9.617,14,21s-6.411,21-14,21-14-9.617-14-21S24.411,6,32,6Z" />
        <path d="M41.293,23.707c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-11-11c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l11,11Z" />
        <path d="M33,43c.256,0,.512-.098,.707-.293,.391-.391,.391-1.023,0-1.414l-11-11c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l11,11c.195,.195,.451,.293,.707,.293Z" />
        <path d="M40.293,16.707c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-4-4c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l4,4Z" />
        <path d="M28.293,44.707c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-4-4c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l4,4Z" />
      </svg>
    </>
  );
}
