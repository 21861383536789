import React from "react";

export default function Type({ studio }) {
  const dataName = "Type";

  return (
    <>
      {studio.type === "home" && (
        <p className="my-1">
          <span className="font-bold">{dataName}:</span> Home Studio
        </p>
      )}
      {studio.type === "mall" && (
        <p className="my-1">
          <span className="font-bold">{dataName}:</span> Mall Studio
        </p>
      )}
      {studio.type === "office" && (
        <p className="my-1">
          <span className="font-bold">{dataName}:</span> Office Studio
        </p>
      )}
    </>
  );
}
