import React from "react";

export default function Lollipop({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad">
          <g>
            <path d="M63.707,59.465l-29.339-29.339c2.274-3.132,3.631-6.969,3.631-11.126,0-3.406-.909-6.706-2.621-9.617,1.045-1.9,.785-4.332-.822-5.94-1.609-1.609-4.041-1.867-5.94-.822-2.91-1.712-6.21-2.621-9.617-2.621C8.523,0,0,8.523,0,19c0,3.407,.909,6.707,2.621,9.617-1.045,1.9-.785,4.332,.822,5.94,.975,.975,2.255,1.462,3.535,1.462,.833,0,1.657-.228,2.405-.64,2.91,1.712,6.21,2.621,9.617,2.621,4.157,0,7.995-1.358,11.126-3.631l29.339,29.339c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293l2.828-2.828c.391-.391,.391-1.023,0-1.414ZM4.857,33.143c-1.169-1.17-1.169-3.073,0-4.243L22.05,11.707c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0L3.98,26.948c-1.294-2.439-1.98-5.153-1.98-7.948C2,9.626,9.626,2,19,2c2.796,0,5.509,.687,7.948,1.98l-3.313,3.313c-.391,.391-.391,1.023,0,1.414s1.023,.391,1.414,0l3.85-3.85c1.169-1.167,3.072-1.169,4.243,0,1.169,1.17,1.169,3.073,0,4.243L9.101,33.143c-1.169,1.167-3.072,1.169-4.243,0Zm6.194,.877L34.019,11.052c1.294,2.439,1.981,5.153,1.981,7.948,0,9.374-7.626,17-17,17-2.795,0-5.509-.687-7.948-1.981Zm49.12,27.566l-28.484-28.484c.496-.447,.967-.918,1.414-1.414l28.484,28.484-1.414,1.414Z" />
            <path d="M19,4c-2.248,0-4.411,.487-6.43,1.448-.499,.238-.711,.835-.474,1.333,.172,.36,.53,.57,.904,.57,.144,0,.29-.031,.429-.097,1.748-.833,3.622-1.255,5.57-1.255,.553,0,1-.447,1-1s-.447-1-1-1Z" />
            <rect x="21" y="4" width="2" height="2" />
          </g>
        </g>
      </svg>
    </>
  );
}
