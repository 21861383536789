import React from "react";

export default function Podcast({ className }) {
  return (
    <svg
      className={className.artist2}
      version="1.0"
      id="Layer_1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      //   style="enable-background:new 0 0 64 64;"
      //   xml:space="preserve"
    >
      <style type="text/css">
        {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
      </style>
      <g>
        <ellipse
          transform="matrix(0.229 -0.9734 0.9734 0.229 -24.3532 44.1074)"
          
          cx="15.67"
          cy="37.43"
          rx="7.25"
          ry="7.25"
        />
        <path
          
          d="M3.42,56.93c0-6.77,5.49-12.25,12.25-12.25c6.77,0,12.25,5.49,12.25,12.25H3.42z"
        />

        <ellipse
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.3096 45.1379)"
          
          cx="48.33"
          cy="37.43"
          rx="7.25"
          ry="7.25"
        />
        <path
          
          d="M36.08,56.93c0-6.77,5.49-12.25,12.25-12.25c6.77,0,12.25,5.49,12.25,12.25H36.08z"
        />
        <path
          
          d="M37.56,13.87h-0.01c-0.76-3.83-6.62-6.79-13.76-6.79c-7.65,0-13.85,3.42-13.85,7.66
     c0,3.66,4.65,6.72,10.87,7.45l0.49,5.61l6.1-5.68c1.38-0.2,2.68-0.53,3.87-0.95"
        />
        <path
          
          d="M30.55,19.13c0,2.54,3.13,4.69,7.37,5.32l4.39,4.09l0.35-4.04c4.48-0.54,7.83-2.74,7.83-5.37
     c0-3.04-4.47-5.51-9.97-5.51C35.01,13.62,30.55,16.09,30.55,19.13z"
        />
        <line  x1="17.91" y1="14.33" x2="18.71" y2="14.33" />
        <line  x1="22.12" y1="14.33" x2="22.92" y2="14.33" />
        <line  x1="26.23" y1="14.33" x2="27.03" y2="14.33" />
        <line  x1="37.68" y1="18.79" x2="38.48" y2="18.79" />
        <line  x1="41.79" y1="18.79" x2="42.59" y2="18.79" />
      </g>
    </svg>
  );
}
