import React from "react";

export default function Water({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        id="_1-drinking_water"
        data-name="1-drinking water"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path d="M47.385,51.083A13.982,13.982,0,0,1,47,48V30.108c.288-1.362,2.315-12.719-8-19.649V8h1a1,1,0,0,0,1-1V3a3,3,0,0,0-3-3H26a3,3,0,0,0-3,3V7a1,1,0,0,0,1,1h1v2.459c-10.315,6.93-8.288,18.287-8,19.649V48a13.982,13.982,0,0,1-.385,3.083A25.8,25.8,0,0,0,16,57a21.1,21.1,0,0,0,.318,3.759A3.975,3.975,0,0,0,20.247,64H43.753a3.975,3.975,0,0,0,3.929-3.241A21.1,21.1,0,0,0,48,57,25.8,25.8,0,0,0,47.385,51.083ZM19,47V41.581a15.088,15.088,0,0,1,6.054-1.556,1,1,0,1,0-.108-2A17.621,17.621,0,0,0,19,39.369V31H45v6.654A7.674,7.674,0,0,1,39,40a1,1,0,0,0,0,2,10.425,10.425,0,0,0,6-1.718V47ZM25,3a1,1,0,0,1,1-1H38a1,1,0,0,1,1,1V6H25Zm1.537,8.844A1,1,0,0,0,27,11V8H37v3a1,1,0,0,0,.463.844A16.765,16.765,0,0,1,45.177,29H18.831A16.958,16.958,0,0,1,26.537,11.844ZM45.714,60.4A1.975,1.975,0,0,1,43.753,62H20.247a1.975,1.975,0,0,1-1.961-1.6A19.065,19.065,0,0,1,18,57a24.053,24.053,0,0,1,.574-5.515A18.581,18.581,0,0,0,18.963,49H45.037a18.581,18.581,0,0,0,.389,2.485A24.053,24.053,0,0,1,46,57,19.065,19.065,0,0,1,45.714,60.4Z" />
        <path d="M31.177,32.432C30.479,33.441,27,38.6,27,41a5,5,0,0,0,10,0c0-2.4-3.479-7.559-4.177-8.568A1.038,1.038,0,0,0,31.177,32.432ZM32,44a3,3,0,0,1-3-3c0-1.092,1.561-3.942,3-6.192,1.439,2.25,3,5.1,3,6.192A3,3,0,0,1,32,44Z" />
        <path d="M38,53H26a1,1,0,0,0,0,2H38a1,1,0,0,0,0-2Z" />
        <path d="M38,57H26a1,1,0,0,0,0,2H38a1,1,0,0,0,0-2Z" />
        <path d="M26,21H38a1,1,0,0,0,0-2H26a1,1,0,0,0,0,2Z" />
        <path d="M26,25H38a1,1,0,0,0,0-2H26a1,1,0,0,0,0,2Z" />
      </svg>
    </>
  );
}
