import React from "react";

export default function Announcer({ className }) {
  return (
    <svg
      className={className.artist2}
      version="1.0"
      id="Layer_1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      //   style="enable-background:new 0 0 64 64;"
      //   xml:space="preserve"
    >
      <style type="text/css">
        {`.st0{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
      </style>
      <g>
        <g>
          <path d="M11.14,53.31c0-11.57,9.38-20.94,20.94-20.94h0c11.57,0,20.94,9.38,20.94,20.94H11.14z" />
          <path
            d="M57.56,53.31H6.44c-0.83,0-1.43,0.8-1.21,1.6l1.29,4.53c0.15,0.54,0.65,0.91,1.21,0.91h48.55
         c0.56,0,1.05-0.37,1.21-0.91l1.29-4.53C58.99,54.1,58.39,53.31,57.56,53.31z"
          />
          <path d="M33.95,48.13h-3.08c-2.1,0-3.81,1.71-3.81,3.81v1.37h10.7v-1.37C37.76,49.84,36.06,48.13,33.95,48.13z" />
          <path
            d="M32.41,48.13L32.41,48.13c-2.49,0-4.51-2.02-4.51-4.51v-2.91c0-2.49,2.02-4.51,4.51-4.51l0,0
         c2.49,0,4.51,2.02,4.51,4.51v2.91C36.92,46.11,34.9,48.13,32.41,48.13z"
          />
          <path
            d="M44.81,19.55c0,7.08-5.74,12.82-12.81,12.82c-7.08,0-12.82-5.74-12.82-12.82c0-2.76,0.87-5.31,2.36-7.4
         c4.37,3.77,13.08,0.69,13.08,0.69c3.1,3.87,9.12,2.42,9.42,2.34C44.54,16.55,44.81,18.02,44.81,19.55z"
          />
          <path d="M19.18,26.36h-0.25c-3.54,0-6.41-2.87-6.41-6.41l0,0c0-3.54,2.87-6.41,6.41-6.41h0.25V26.36z" />
          <path d="M32.08,3.66c-3.77,0-6.6,0.94-8.71,2.25" />
          <path d="M19.66,9.32c-1.71,2.28-2.19,4.39-2.19,4.39" />
          <path d="M44.9,26.36h0.25c3.54,0,6.41-2.87,6.41-6.41l0,0c0-3.54-2.87-6.41-6.41-6.41H44.9V26.36z" />
          <path d="M46.61,13.71c0,0-2.27-10.05-14.61-10.05" />
          <path d="M44.05,15.18C44.05,15.18,44.05,15.18,44.05,15.18" />
          <line x1="19.18" y1="50.03" x2="19.18" y2="53.31" />
          <line x1="45.5" y1="50.03" x2="45.5" y2="53.31" />
          <path d="M39.01,33.54L39,33.55c-1.48,1.74-2.93,2.75-4.32,3.27" />
          <path d="M30.01,36.91c-2.91-0.94-4.85-3.37-4.85-3.37" />
        </g>
        <line x1="24.59" y1="19.07" x2="27.06" y2="19.07" />
        <line x1="36.53" y1="19.07" x2="39" y2="19.07" />
        <line x1="30.76" y1="26.9" x2="33.23" y2="26.9" />
      </g>
    </svg>
  );
}
