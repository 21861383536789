import NewBorn from "../studioPropIcons/NewBorn";
import Pregnancy from "../studioPropIcons/Pregnancy";
import Toddler from "../studioPropIcons/Toddler";
import Rings from "../studioPropIcons/Rings";
import Lipstick from "../studioPropIcons/Lipstick";
import Mask from "../studioPropIcons/Mask";
import Celebration from "../studioPropIcons/Celebration";
import Cake from "../studioPropIcons/Cake";
import DogWithBone from "../studioPropIcons/DogWithBone";
import FamilyPhoto from "../studioPropIcons/FamilyPhoto";

export const studioPropsArray = (className) => [
  {
    id: "props",
    name: "props",
    title: "New Born",
    value: "newBorn",
    type: "checkbox",
    icon: <NewBorn className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/pretty-baby-in-cradle-2023-11-27-05-13-21-utc.jpg?updatedAt=1714444943615",
  },
  {
    id: "props",
    name: "props",
    title: "Toddlers",
    value: "toddler",
    type: "checkbox",
    icon: <Toddler className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/cute-red-haired-girl-playing-with-ducklings-easter-2023-11-27-05-36-04-utc.jpg?updatedAt=1714444938570",
  },
  {
    id: "props",
    name: "props",
    title: "Family",
    value: "family",
    type: "checkbox",
    icon: <FamilyPhoto className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/IMG-20240429-WA0000.jpg?updatedAt=1714374265287",
  },
  {
    id: "props",
    name: "props",
    title: "Birthdays",
    value: "birthday",
    type: "checkbox",
    icon: <Cake className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/girl-sitting-and-covering-face-with-pink-air-ballo-2023-11-27-04-53-38-utc.jpg?updatedAt=1714444939699",
  },
  {
    id: "props",
    name: "props",
    title: "Pregnancy",
    value: "pregnancy",
    type: "checkbox",
    icon: <Pregnancy className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/black-woman-sitting-with-flower-pregnant-with-smi-2023-11-27-05-27-44-utc.jpeg?updatedAt=1714593345389",
  },
  {
    id: "props",
    name: "props",
    title: "Couples",
    value: "couples",
    type: "checkbox",
    icon: <Rings className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/cheerful-asian-woman-sitting-on-armchair-near-tren-2023-11-27-05-03-43-utc.jpg?updatedAt=1714447765248",
  },
  {
    id: "props",
    name: "props",
    title: "Fun",
    value: "fun",
    type: "checkbox",
    icon: <Celebration className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/jpeg-optimizer_carnival-background-on-a-wooden-surface-masks-co-2023-11-27-05-33-07-utc.jpeg?updatedAt=1717225822670",
  },
  {
    id: "props",
    name: "props",
    title: "Pets",
    value: "pets",
    type: "checkbox",
    icon: <DogWithBone className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/dog-seated-wearing-mexican-charro-hat-2023-11-27-05-17-35-utc.jpeg?updatedAt=1714594452826",
  },
  {
    id: "props",
    name: "props",
    title: "Products",
    value: "products",
    type: "checkbox",
    icon: <Lipstick className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/handsome-guy-professional-photographer-taking-phot-2023-11-27-04-59-37-utc.jpeg?updatedAt=1714594452768",
  },
  {
    id: "props",
    name: "props",
    title: "Adult",
    value: "adult",
    type: "checkbox",
    icon: <Mask className={className} />,
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/propsImages/top-view-of-black-sex-toys-on-pink-background-2023-11-27-04-59-20-utc.jpeg?updatedAt=1716696531739",
  },
];
