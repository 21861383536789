import React from "react";

export default function Drone({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`">
          <g>
            <path d="M29,46c0,1.654,1.346,3,3,3s3-1.346,3-3-1.346-3-3-3-3,1.346-3,3Zm4,0c0,.552-.449,1-1,1s-1-.448-1-1,.449-1,1-1,1,.448,1,1Z" />
            <path d="M55,17h-6c-.552,0-1,.447-1,1v1.895l-8.827-.883c-.593-.474-1.359-.826-2.339-.954-.398-1.191-1.51-2.058-2.834-2.058h-4c-1.323,0-2.436,.867-2.834,2.058-.98,.128-1.746,.48-2.339,.954l-8.827,.883v-1.895c0-.553-.448-1-1-1h-6c-.552,0-1,.447-1,1v6c0,2.206,1.794,4,4,4,1.858,0,3.411-1.279,3.858-3h7.142v1.338c-1.23,.566-3.984,2.063-6.742,5.117-3.311,3.665-7.258,10.379-7.258,21.545,0,.553,.448,1,1,1h7c.552,0,1-.447,1-1s-.448-1-1-1h-1.988c.088-3.573,.645-6.547,1.419-9h1.569c.552,0,1-.447,1-1s-.448-1-1-1h-.848c.8-1.96,1.721-3.508,2.577-4.68,1.969-2.692,3.947-3.975,4.534-4.32h2.921c.414,1.161,1.514,2,2.816,2-.552,0-1,.447-1,1s.448,1,1,1h-2c-.552,0-1,.447-1,1s.448,1,1,1h3v2h-4c-.552,0-1,.447-1,1v10c0,.553,.448,1,1,1h10c.552,0,1-.447,1-1v-10c0-.553-.448-1-1-1h-4v-2h3c.552,0,1-.447,1-1s-.448-1-1-1h-2c.552,0,1-.447,1-1s-.448-1-1-1c1.302,0,2.402-.839,2.816-2h2.921c.585,.344,2.563,1.625,4.534,4.32,.857,1.172,1.777,2.719,2.577,4.68h-.848c-.552,0-1,.447-1,1s.448,1,1,1h1.569c.774,2.453,1.331,5.427,1.419,9h-1.988c-.552,0-1,.447-1,1s.448,1,1,1h7c.552,0,1-.447,1-1,0-11.166-3.947-17.88-7.258-21.545-2.759-3.054-5.513-4.551-6.742-5.117v-1.338h7.142c.447,1.721,2,3,3.858,3,2.206,0,4-1.794,4-4v-6c0-.553-.448-1-1-1Zm-19,33h-8v-8h8v8Zm14-31h4v4h-4v-4Zm-25,4.006c.005-.435,.134-2.369,2-2.881v9.875h-2v-6.994Zm-15-4.006h4v4h-4v-4Zm2,7c-.737,0-1.375-.405-1.722-1h3.443c-.347,.595-.985,1-1.722,1Zm4-3v-1.095l7.33-.733c-.234,.661-.33,1.313-.33,1.828h-7Zm-2.982,29h-2.007c.342-15.649,8.664-21.656,11.989-23.437v1.859c-2.141,1.328-9.648,7.023-9.982,21.578Zm20.982-20h-4c-.551,0-1-.448-1-1v-12c0-.552,.449-1,1-1h4c.551,0,1,.448,1,1v12c0,.552-.449,1-1,1Zm5-2h-2v-9.875c1.866,.512,1.995,2.445,2,2.875v7Zm13.989,22h-2.007c-.334-14.555-7.84-20.25-9.982-21.578v-1.838c3.307,1.822,11.643,7.937,11.989,23.416Zm-12.319-30.828l7.33,.733v1.095h-7c0-.515-.096-1.167-.33-1.828Zm11.33,4.828c-.737,0-1.375-.405-1.722-1h3.443c-.347,.595-.985,1-1.722,1Z" />
            <path d="M51.278,14.04c-1.649-.479-7.122-2.04-8.278-2.04-1.654,0-3,1.346-3,3s1.346,3,3,3c1.157,0,6.629-1.562,8.278-2.04,.428-.123,.722-.515,.722-.96s-.294-.837-.722-.96Zm-8.278,1.96c-.551,0-1-.448-1-1s.449-1,1-1c.409,.004,2.182,.434,4.288,1-2.105,.566-3.879,.996-4.287,1Z" />
            <path d="M61,12c-1.157,0-6.629,1.562-8.278,2.04-.428,.123-.722,.515-.722,.96s.294,.837,.722,.96c1.649,.479,7.122,2.04,8.278,2.04,1.654,0,3-1.346,3-3s-1.346-3-3-3Zm0,4c-.409-.004-2.182-.434-4.288-1,2.105-.566,3.879-.996,4.287-1,.551,0,1,.448,1,1s-.449,1-1,1Z" />
            <path d="M52,12c.551,0,1,.448,1,1h2c0-1.654-1.346-3-3-3s-3,1.346-3,3h2c0-.552,.449-1,1-1Z" />
            <path d="M12.722,15.96c1.649,.479,7.122,2.04,8.278,2.04,1.654,0,3-1.346,3-3s-1.346-3-3-3c-1.157,0-6.629,1.562-8.278,2.04-.428,.123-.722,.515-.722,.96s.294,.837,.722,.96Zm8.278-1.96c.551,0,1,.448,1,1s-.449,1-1,1c-.409-.004-2.182-.434-4.288-1,2.105-.566,3.879-.996,4.287-1Z" />
            <path d="M12,15c0-.445-.294-.837-.722-.96-1.649-.479-7.122-2.04-8.278-2.04-1.654,0-3,1.346-3,3s1.346,3,3,3c1.157,0,6.629-1.562,8.278-2.04,.428-.123,.722-.515,.722-.96Zm-9,1c-.551,0-1-.448-1-1s.449-1,1-1c.409,.004,2.182,.434,4.288,1-2.105,.566-3.879,.996-4.287,1Z" />
            <path d="M12,12c.551,0,1,.448,1,1h2c0-1.654-1.346-3-3-3s-3,1.346-3,3h2c0-.552,.449-1,1-1Z" />
          </g>
        </g>
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
