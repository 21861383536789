import React from "react";

export default function Description({ packageDocReady, packageShow }) {
  return (
    <>
      <div className="mx-2 md:mx-0 my-5">
        {packageDocReady && <p>{packageShow.description}</p>}
      </div>
    </>
  );
}
