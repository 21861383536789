import React, { useEffect, useState } from "react";
import { studioAmenityArray } from "../../assets/globalArrays/studioAmenityArray";

export default function Amenities({
  studio,
  containerClass,
  iconClass,
  studioDocReady,
}) {
  const [amenities, setAmenities] = useState("");
  const [displaySection, setDisplaySection] = useState(true);

  useEffect(() => {
    if (studioDocReady) {
      setAmenities(studio.amenities);
    }
  }, [studioDocReady, studio]);

  const display = () => {
    setDisplaySection(!displaySection);
  };

  return (
    <>
      <div>
        <p className="cursor-pointer" onClick={display}>
          Studio Amenities
        </p>
        {displaySection && (
          <div className="grid grid-cols-2 md:grid-cols-4 md:gap-4">
            {amenities &&
              amenities.map((item) => {
                const i = studioAmenityArray().findIndex(
                  (e) => e.value === item
                );
                console.log("Item: ", item);
                console.log("i: ", i);
                const icon = studioAmenityArray(iconClass)[i].icon;
                const title = studioAmenityArray()[i].title;
                return (
                  <div key={item} className={containerClass}>
                    <span>{icon}</span>
                    <span className="capitalize ml-2 text-xs">{title}</span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
