import React, { useState, useEffect } from "react";
import { videoCamsArray } from "../../assets/globalArrays/videoCamsArray";

export default function VideoCams({
  studioDocReady,
  studio,
  containerClass,
  iconClass,
}) {
  const [videoCams, setVideoCams] = useState();
  const [displaySection, setDisplaySection] = useState(false);

  useEffect(() => {
    if (studioDocReady) {
      setVideoCams(studio.videoCams);
    }
  }, [studioDocReady, studio]);

  const display = () => {
    setDisplaySection(!displaySection);
    console.log("Click Video Cameras", displaySection);
  };

  return (
    <>
      <div>
        <p className="cursor-pointer" onClick={display}>
          Video Cameras
        </p>
        {displaySection && (
          <div className="grid grid-cols-2 md:grid-cols-4 md:gap-4">
            {videoCams &&
              videoCams.map((item) => {
                const i = videoCamsArray().findIndex((e) => e.value === item);
                // const i = 0;
                // console.log("Item: ", item);
                // console.log("i: ", i);
                const icon = videoCamsArray(iconClass)[i].icon;
                const title = videoCamsArray()[i].title;
                return (
                  <div key={item} className={containerClass}>
                    <span>{icon}</span>
                    <span className="capitalize ml-2 text-xs">{title}</span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
