import React from "react";

export default function ShootFlexibility({ addData }) {
  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };
  return (
    <>
      <div className="m-4">
        <h3>Shoot Flexibility</h3>
        <div className="flex items-center mb-4">
          <select
            required
            id="shootFlexibility"
            name="shootFlexibility"
            onChange={onChange}
            className="w-full"
          >
            <option value="" defaultValue="Please Select" selected disabled>
              Please Select
            </option>
            <option value="only available at this date and time">
              I'm only available at this date and time
            </option>
            <option value="only available on this date but flexible on time">
              I'm only available on this date but flexible on time
            </option>
            <option value="flexible on both date and time">
              I'm flexible on both date and time
            </option>
          </select>
        </div>
      </div>
    </>
  );
}
