import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../firebase";

import EventAddress from "../../assets/formInputComponents/EventAddress";
import EventDate from "../../assets/formInputComponents/EventDate";
import EventTime from "../../assets/formInputComponents/EventTime";
import EventDetails from "../../assets/formInputComponents/EventDetails";
import ContactPerson from "../../assets/formInputComponents/ContactPerson";
import ContactEmail from "../../assets/formInputComponents/ContactEmail";
import ContactNumber from "../../assets/formInputComponents/ContactNumber";
import StudioShootDetails from "../../assets/formInputComponents/StudioShootDetails";
import ShootFlexibility from "../../assets/formInputComponents/ShootFlexibility";

export default function Price({ packageShow, packageDocReady, profile }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const [formData, setFormData] = useState([]);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [region, setRegion] = useState("");
  const hostname = window.location.hostname;

  useEffect(() => {
    console.log(formData);
    if (formData.address) {
      setAddress(formData.address);
      setRegion(formData.region);
    }
  }, [formData]);

  useEffect(() => {
    if (address && region === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [address, region]);

  const addData = useCallback((id, data) => {
    if (hostname === "localhost") {
      console.log("Local onChange: ", id, data);
    }

    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (hostname === "localhost") {
      console.log("Submission Data: ", formData);
    }

    const docRef = collection(db, "packageBooking");

    try {
      await addDoc(docRef, {
        ...formData,
        submittedAt: serverTimestamp(),
        completed: false,
        professional: profile.name,
        user: profile.user,
        status: "just submitted",
        package: packageShow,
      });
      toast.success("Your form has been submitted, we will be in touch.");
      setFormData([]);
      // document.getElementById("form").reset();
      setOpen(false);
      console.log("After Submit: ", formData);
    } catch (error) {
      console.log(error.message);
      toast.error(
        "Something went wrong with your submission, please try again"
      );
    }
  };

  return (
    <>
      {packageDocReady && (
        <div className="sticky left-0 bottom-0 w-full bg-white flex justify-center">
          <>
            <Button
              onClick={handleOpen}
              variant="gradient"
              color="orange"
              className="w-full rounded-sm shadow-md md:w-1/6 my-1 text-white text-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 p-2 transition-all ease-in-out delay-75"
            >
              R{packageShow.price}
            </Button>
            <Dialog open={open} handler={handleOpen}>
              <DialogHeader>Booking: {packageShow.name} </DialogHeader>
              <form onSubmit={onSubmit}>
                <DialogBody className="h-80 overflow-scroll">
                  <EventDate addData={addData} />
                  <EventTime addData={addData} />
                  {packageShow.type === "onSite" && (
                    <EventAddress addData={addData} />
                  )}
                  <ShootFlexibility addData={addData} />
                  {packageShow.type === "onSite" && (
                    <EventDetails addData={addData} />
                  )}
                  {packageShow.type === "studio" && (
                    <StudioShootDetails addData={addData} />
                  )}

                  <ContactPerson addData={addData} />
                  <ContactEmail addData={addData} />
                  <ContactNumber addData={addData} />
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button variant="gradient" color="green" type="submit">
                    <span>Make Booking</span>
                  </Button>
                </DialogFooter>
              </form>
            </Dialog>
          </>
        </div>
      )}
    </>
  );
}
