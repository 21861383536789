import React, { useEffect } from "react";
import avatar from "../../assets/Avatar/photo.svg";
import Availability from "./generalInfoComponents/Availability";
import Type from "./generalInfoComponents/Type";

export default function GeneralInfo({ studio, studioDocReady, profile }) {
  useEffect(() => {
    console.log("GeneralInfo Profile: ", profile);
  }, [profile]);

  return (
    <>
      {studioDocReady && (
        <div className="w-full">
          <div className="flex justify-center items-center my-5">
            <img
              src={
                profile?.profileImageURL ===
                  "/static/media/logo.7e36e6aeac51429ea175.png" ||
                profile?.profileImageURL === ""
                  ? avatar
                  : `https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/${profile?.profileImageURL}`
              }
              alt="profile"
              className="inline-block align-middle text-center ml-1 mt-1 p-1 my-auto ring-2 ring-orange-400 object-cover w-28 h-28 rounded-full"
              loading="lazy"
              key={profile?.user}
            />
          </div>
          <div className="mx-2 md:mx-0">
            <Type studio={studio} />

            <Availability studio={studio} />

            <p className="my-1">
              <span className="font-bold">Size:</span> {studio?.size}m
              <sup>2</sup>
            </p>
            <p className="my-1">
              <span className="font-bold">Location:</span> {studio?.region}
            </p>
            <p className="my-1">{studio?.description}</p>
          </div>
        </div>
      )}
    </>
  );
}
