import React from "react";

export default function PetInsurance({ className }) {
  return (
    <>
      <svg
        className={className.artist1}
        id="_28-pet_insurance"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path d="M49.58,30.347c.571,1.571,.902,3.153,.981,4.705,.029,.552,.502,.965,1.051,.947,.551-.028,.976-.499,.947-1.051-.091-1.749-.461-3.527-1.101-5.285-1.755-4.823-4.9-6.293-7.229-6.677-1.531-.251-2.902-.072-3.752,.113-1.483-2.785-6.277-8.438-14.652-5.39-5.012,1.824-6.891,5.211-7.584,7.73-.635,2.309-.462,4.493-.175,5.825-2.048,1.099-6.916,4.602-4.194,12.078,1.319,3.628,3.619,6.612,6.476,8.406,1.787,1.122,3.701,1.709,5.441,1.709,.803,0,1.568-.125,2.268-.38,2.784-1.014,4.03-2.457,5.13-3.731,.896-1.038,1.669-1.936,3.241-2.508,.52-.188,.787-.763,.598-1.281-.188-.52-.765-.786-1.281-.598-2.062,.751-3.084,1.935-4.072,3.08-1.031,1.195-2.005,2.324-4.299,3.159-1.642,.6-3.87,.169-5.962-1.145-2.481-1.558-4.491-4.185-5.66-7.396-2.688-7.387,3.577-9.813,3.842-9.912,.249-.091,.452-.276,.564-.517,.112-.241,.124-.516,.033-.766-.243-.666-.72-2.953-.021-5.494,.826-3.005,2.96-5.152,6.339-6.383,7.796-2.835,11.824,3.251,12.476,5.038,.188,.517,.763,.783,1.278,.598,.27-.095,6.628-2.266,9.317,5.122Z" />
        <path d="M17.668,20.38c.535,0,1.066-.09,1.579-.276,1.567-.57,2.719-1.922,3.242-3.808,.49-1.765,.369-3.811-.341-5.762-1.51-4.146-5.269-6.597-8.373-5.465-1.567,.57-2.719,1.922-3.243,3.808-.49,1.765-.37,3.811,.341,5.762,1.26,3.461,4.086,5.741,6.795,5.741Zm-5.209-10.968c.347-1.245,1.057-2.12,2-2.464,.293-.106,.598-.157,.91-.157,1.859,0,3.947,1.811,4.9,4.427,.568,1.563,.673,3.177,.293,4.543-.347,1.245-1.057,2.12-1.999,2.464-2.044,.743-4.698-1.214-5.812-4.27-.568-1.563-.673-3.177-.293-4.543Z" />
        <path d="M30.823,15.595c.535,0,1.066-.09,1.579-.276,1.567-.57,2.719-1.922,3.243-3.808,.49-1.765,.369-3.811-.341-5.762s-1.933-3.597-3.442-4.633C30.247,.007,28.496-.287,26.931,.285c-1.567,.57-2.719,1.922-3.243,3.807-.49,1.765-.369,3.811,.341,5.762,1.26,3.461,4.086,5.742,6.795,5.742Zm-5.209-10.968c.347-1.245,1.057-2.12,2.001-2.464,.294-.107,.604-.16,.923-.16,.705,0,1.458,.259,2.192,.762,1.169,.803,2.126,2.105,2.695,3.668,.568,1.562,.673,3.176,.293,4.543-.347,1.245-1.057,2.12-2,2.464-2.035,.741-4.698-1.213-5.812-4.271-.568-1.562-.673-3.176-.293-4.542Z" />
        <path d="M44.98,21.647c3.859,0,7-4.037,7-9s-3.141-9-7-9-7,4.037-7,9,3.141,9,7,9Zm0-16c2.757,0,5,3.141,5,7s-2.243,7-5,7-5-3.141-5-7,2.243-7,5-7Z" />
        <path d="M15.769,27.696c-.114-2.101-1.011-4.249-2.522-6.051-1.511-1.802-3.472-3.058-5.521-3.535-2.157-.504-4.152-.099-5.626,1.141-2.957,2.48-2.768,7.592,.423,11.394,2.027,2.417,4.786,3.73,7.287,3.73,1.434,0,2.782-.432,3.859-1.335,1.476-1.237,2.221-3.135,2.1-5.344Zm-11.715,1.663c-2.48-2.957-2.78-6.805-.669-8.577,.693-.581,1.585-.88,2.587-.88,.416,0,.852,.052,1.3,.156,1.63,.38,3.208,1.4,4.443,2.872,1.235,1.473,1.966,3.204,2.058,4.875,.085,1.564-.408,2.879-1.389,3.702-2.109,1.771-5.847,.809-8.33-2.148Z" />
        <path d="M63,42c-.132,0-3.229-.037-4.03-3.242-.111-.445-.511-.758-.97-.758h-12c-.459,0-.858,.312-.97,.758-.793,3.169-3.903,3.24-4.03,3.242-.553,0-1,.447-1,1v9c0,.065,.007,.132,.02,.196,.079,.394,2.041,9.666,11.768,11.781,.07,.015,.142,.022,.213,.022s.143-.008,.213-.022c9.727-2.115,11.688-11.388,11.768-11.781,.013-.064,.02-.131,.02-.196v-9c0-.553-.447-1-1-1Zm-1,9.893c-.217,.95-2.131,8.249-10,10.082-7.874-1.834-9.785-9.14-10-10.082v-7.992c1.606-.303,3.781-1.349,4.735-3.9h10.529c.954,2.552,3.129,3.598,4.735,3.9v7.992Z" />
        <path d="M56.276,42.439c-.186-.274-.496-.439-.828-.439h-6.896c-.332,0-.643,.165-.828,.439-.777,1.149-1.839,2.054-3.157,2.689-.347,.166-.566,.517-.566,.9v5.494c0,.091,.013,.182,.037,.27,.409,1.464,2.173,6.369,7.673,8.035,.095,.028,.192,.043,.29,.043s.196-.015,.291-.043c5.473-1.662,7.255-6.574,7.671-8.04,.025-.088,.038-.181,.038-.272v-5.486c0-.384-.22-.734-.566-.9-1.316-.635-2.379-1.54-3.157-2.689Zm1.724,8.933c-.399,1.326-1.867,5.047-6,6.449-4.154-1.404-5.607-5.118-6-6.439v-4.745c1.22-.678,2.247-1.562,3.064-2.637h5.871c.818,1.075,1.846,1.959,3.064,2.637v4.735Z" />
        <path d="M54.168,46.445l-3.323,4.985-1.138-1.138c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l2,2c.188,.188,.443,.293,.707,.293,.032,0,.065-.002,.099-.005,.298-.029,.567-.191,.733-.44l4-6c.307-.46,.183-1.08-.277-1.387-.462-.306-1.082-.182-1.387,.277Z" />
      </svg>
    </>
  );
}
