import React from "react";

export default function NewBorn({ className }) {
  return (
    <>
      <svg
        className={className.artist2}
        // className={className}
        // version="1.0"
        // id="Layer_1"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        // style="enable-background:new 0 0 64 64;"
        // xml:space="preserve"
      >
        {/* <style type="text/css">
          {`.st0{fill:#e30e43;stroke:#e30e43;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
          {`.st1{fill:#e30e43;stroke:#e30e43;stroke-width:1.8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        </style> */}
        <g>
          <path
            d="M42.83,18.74c0.4,0.33,0.79,0.69,1.16,1.06c3.08,3.07,4.98,7.32,4.98,12v13.32c0,9.39-7.6,16.99-16.97,16.99
     c-9.37,0-16.98-7.6-16.98-16.99V31.79c0-5.24,2.39-9.95,6.14-13.05"
          />
          <path d="M15.03,45.12c0,0,10.68-13.33,33.94-13.33" />
          <path d="M28.74,35.69c0,0-9.68-6.29-13.71-5.37" />
          <circle cx="32" cy="13.8" r="11.9" />
          <path
            d="M32.48,1.91c0,0,1.57,0.69,2.06,2.92c0.38,1.72-0.4,3.84-2.54,4.51c-2.73,0.85-5.51-1.64-3.61-3.53
     c0.4-0.4,1.23-0.62,1.84-0.17"
          />
          <path
            d="M21.17,18.73c-0.45,0.49-0.99,0.79-1.58,0.79c-1.54,0-2.79-2.06-2.79-4.59c0-2.54,1.25-4.6,2.79-4.6
     c0.33,0,0.64,0.09,0.94,0.28"
          />
          <path
            d="M43.46,10.6c0.3-0.18,0.61-0.28,0.94-0.28c1.54,0,2.79,2.06,2.79,4.6c0,2.53-1.25,4.59-2.79,4.59
     c-0.59,0-1.13-0.3-1.58-0.79"
          />
        </g>
      </svg>
    </>
  );
}
