import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function PackagesPreview() {
  const [packages, setPackages] = useState([]);
  //   const [packagesDocsReady, setPackageDocsReady] = useState(false);
  //   const [packagesId, setPackagesId] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "packages");
      const q = query(
        colRef,
        where("adminPublish", "==", "true"),
        where("userPublish", "==", "true"),
        where("images", "!=", "[]")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      //   const proIDs = snapshots.docs.map((doc) => doc.data().user);

      //   const docsId = snapshots.docs.map((doc) => doc.id);
      //   setPackagesId(docsId);
      setPackages(docs);
      // setUserIDs(new Set(proIDs));
      //   setPackageDocsReady(true);
    })();
  }, []);

  //   useEffect(() => {
  //     console.log("Packages: ", packages);
  //   }, [packages]);

  //   useEffect(() => {
  //     if (packagesDocsReady) {
  //       packages.forEach((element) => {
  //         const imageKitUrl = element.images[0].replace(
  //           "https://storage.googleapis.com/",
  //           "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/"
  //         );

  //         element.push({ preview: imageKitUrl });
  //       });
  //     }
  //   }, [packages, packagesDocsReady]);

  //   return <div>PackagesPreview</div>;
  return (
    <>
      <p className="text-3xl text-center font-light">Packages</p>
      <div
        onClick={() => navigate("/packages")}
        className="cursor-pointer bg-orange-100 relative z-5 overflow-x-scroll flex flex-none no-wrap h-80"
      >
        {packages.map((item) => (
          <div className="mx-1 md:mx-2 my-auto relative flex flex-shrink-0">
            <span className="absolute px-1 text-orange-600 w-full truncate left-0 top-0">
              {item.name}
            </span>
            <img
              className="h-72 rounded-lg flex-shrink-0"
              src={item.images[1]}
              alt={`${item.name} preview-1`}
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </>
  );
}
