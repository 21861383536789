import React from "react";

export default function DuringShoot({ packageShow, packageDocReady, heading }) {
  return (
    <>
      {packageDocReady && (
        <div className="my-5">
          <p className={heading}>While Shooting</p>
          <div className="mx-2 md:mx-0 grid md:grid-cols-2">
            <div>Shoot Duration: {packageShow.shootHours} hours</div>
            {packageShow.teamSize > 1 ? (
              <div>Team Size: {packageShow.teamSize} people</div>
            ) : (
              <div>Team Size: {packageShow.teamSize} person</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
