import React from "react";

export default function ContactEmail({ addData }) {
  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };
  return (
    <>
      <div className="m-5" onChange={onChange}>
        <label className="">
          <span className="mr-2">Contact Email</span>
          <input
            className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
            type="email"
            id="email"
            required
          />
        </label>
      </div>
    </>
  );
}
