import React from "react";
import avatar from "../../assets/Avatar/photo.svg";

import { IoMdStar } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function AboutProfessional({
  profile,
  profileDocReady,
  packageShow,
  packageDocReady,
  heading,
}) {
  const navigate = useNavigate();

  const review = (
    profile?.reviewsPublishedRating / profile?.reviewsPublishedNo
  ).toFixed(1);

  return (
    <>
      <div>
        {profileDocReady && profile && (
          <div>
            <p className={heading}>About {profile.name}</p>
          </div>
        )}
        <div className="relative my-5 w-full">
          <div className="flex items-center justify-center">
            <img
              src={
                profile?.profileImageURL ===
                  "/static/media/logo.7e36e6aeac51429ea175.png" ||
                profile?.profileImageURL === ""
                  ? avatar
                  : `https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/${profile?.profileImageURL}`
              }
              alt="profile"
              className="cursor-pointer inline-block align-middle text-center ml-1 mt-1 p-1 my-auto ring-2 ring-orange-400 object-cover w-28 h-28 rounded-full"
              loading="lazy"
              onClick={() => navigate(`/portfolio/${profile.user}`)}
              key={profile?.user}
            />
          </div>
          {review > 0 && (
            <div>
              <div
                //   onClick={() => setShow(true)}
                className="cursor-pointer mx-auto text-center rounded-lg w-1/3 md:w-32 bg-white shadow-sm p-5"
              >
                <IoMdStar
                  title="reviews"
                  className="text-center inline-block text-3xl text-orange-400 hover:text-orange-400"
                />
                <span className="text-center font-bold text-lg inline-block align-bottom hover:underline">
                  {review}
                </span>
                {profile.reviewsPublishedNo > 1 ? (
                  <p className="text-gray-400 text-sm w-full">
                    {profile.reviewsPublishedNo} reviews
                  </p>
                ) : (
                  <p className="text-gray-400 text-sm w-full">
                    {profile.reviewsPublishedNo} review
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="w-3/4 mx-auto">
            <p className="text-center text-xl">{profile?.name}</p>
            <p className="text-center text-gray-600">{profile?.aboutyou}</p>
          </div>
        </div>
      </div>
    </>
  );
}
